import { Card, Form, Input, InputNumber, Select } from "antd";
import React from "react";
import { MinusCircleOutlined } from "@ant-design/icons";
const { Option } = Select;
const NormalCoin = (props) => {
  const { field, remove, coins } = props;
  console.log(coins);
  return (
    <Card key={field.key}>
      <MinusCircleOutlined
        style={{ color: "red" }}
        onClick={() => remove(field.name)}
      />

      <Form.Item {...field} name={[field.name, "symbol"]} label="Coin">
        <Select>
          {coins.map((coin) => (
            <Option value={coin.symbol} key={coin.symbol}>
              {coin.symbol}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Card>
  );
};

export default NormalCoin;
