import { Table } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATEMENT_TYPE_NAME } from "../../constants/statements";
import {
  investorStatementsSelector,
  loadInvestorStatements,
} from "../../slices/statement.slice";
import Container from "../commons/Container";

const InvestorStatement = () => {
  const statements = useSelector(investorStatementsSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadInvestorStatements());
  }, []);

  const columns = [
    {
      key: "title",
      title: "Title",
      dataIndex: "title",
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
      render: (type) => STATEMENT_TYPE_NAME[type],
    },
    {
      key: "file",
      title: "Document",
      dataIndex: "file",
      render: (file) => (
        <a href={file} target="_blank" rel="noreferrer">
          View Doc
        </a>
      ),
    },
    {
      key: "date",
      title: "Created At",
      dataIndex: "createdAt",
      render: (date) => moment(date).format("DD MMM YYYY HH:mm"),
    },
  ];
  return (
    <Container>
      <Table dataSource={statements} columns={columns} />
    </Container>
  );
};

export default InvestorStatement;
