import React from "react";
import Container from "../commons/Container";
import "./Portfolio.scss";
import requireAuth from "../hoc/requireAuth";

const Portfolio = (props) => {
  console.log("portfolio");
  return <Container>Portfolio</Container>;
};

export default requireAuth(Portfolio);
