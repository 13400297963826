import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const RequireAuth = (props) => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  if (!token) return <Navigate to="/login" state={{ from: location }} />;
  return props.children;
};

export default RequireAuth;
