import { Button, Col, Image, Input, PageHeader, Row, Typography } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enable2FA, userSelector } from "../../../slices/auth.slice";
import "./Security.scss";

const Security = (props) => {
  const { onBack } = props;
  const [code, setCode] = useState();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const update2FA = async () => {
    await dispatch(
      enable2FA({
        enabled: !user.enable2FA,
        code,
      })
    );
    onBack();
  };
  return (
    <div className="security">
      <PageHeader title="Back" onBack={() => onBack()} />
      <Row>
        <Col span={6} offset={4}>
          {!user.enable2FA && (
            <>
              <Typography.Title level={5}>
                Scan this QR code with Google Authenticator App:
              </Typography.Title>
              <Image src={user.qrUrl} />
              <Typography.Title level={4} copyable>
                {user.secret2FA}
              </Typography.Title>
            </>
          )}

          <Typography.Title level={5}>
            Verify Google Authenticator code to{" "}
            {user.enable2FA ? "disable" : "enable"} 2FA:
          </Typography.Title>
          <Input value={code} onChange={(e) => setCode(e.target.value)} />
          <Button type="primary" onClick={update2FA}>
            Submit
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Security;
