import { EditOutlined } from "@ant-design/icons";
import { Button, PageHeader, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_NAME } from "../../../constants/user";
import { getUsers, usersSelector } from "../../../slices/auth.slice";
import { includeInsensitiveStr } from "../../../utils/general";
import { get } from "lodash";
import NewUser from "./NewUser";
import SearchBox from "../../commons/SearchBox";

const Users = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState();
  const users = useSelector(usersSelector);
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([]);
  const filterFn = (row, searchTxt) => {
    return (
      includeInsensitiveStr(get(row, "firstName"), searchTxt) ||
      includeInsensitiveStr(get(row, "lastName"), searchTxt)
    );
  };
  useEffect(() => {
    dispatch(getUsers());
  }, []);

  if (isEditing) {
    return (
      <NewUser
        user={user}
        onClose={() => {
          setIsEditing(false);
          setUser(null);
        }}
      />
    );
  }
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role) => ROLE_NAME[role],
    },
    {
      title: "Action",
      key: "action",
      render: (user) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            setUser(user);
            setIsEditing(true);
          }}
        />
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        extra={[
          <Button key="newcoin" onClick={() => setIsEditing(true)}>
            New User
          </Button>,
        ]}
      ></PageHeader>
      <SearchBox
        source={users}
        onFiltered={setFilterData}
        filterFn={filterFn}
      />
      <Table dataSource={filterData} columns={columns} />
    </div>
  );
};

export default Users;
