import axios from "axios";
import network from "./network";

export const uploadImage = async ({ folder, file, fileType = "image/png" }) => {
  const res = await network.get({
    url: "/upload/image",
    params: {
      folder,
      fileType,
    },
  });
  const { link, key } = res;
  console.log(link, key);
  await axios.put(link, file.originFileObj, {
    headers: { "Content-Type": fileType },
  });
  console.log("done upload file");
  return key;
};
