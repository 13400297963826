import { EditOutlined } from "@ant-design/icons";
import { Button, PageHeader, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fundUsersSelector, loadFundUsers } from "../../../slices/fund.slice";
import NewFundTx from "../FundTx/NewFundTx";
import "./FundUsers.scss";
import { get } from "lodash";

const { Title } = Typography;

const FundUsers = (props) => {
  const { onClose, fund } = props;
  const [isAddInvestor, setIsAddInvestor] = useState(false);
  const [investorId, setInvestorId] = useState(null);
  const dispatch = useDispatch();
  const fundUsers = useSelector(fundUsersSelector).filter(
    (fundUser) => fundUser.units && fundUser.user
  );
  const unitUsed = fundUsers.reduce((acc, fundUser) => acc + fundUser.units, 0);

  useEffect(() => {
    fund && dispatch(loadFundUsers({ fund: fund.id }));
  }, [fund]);

  if (isAddInvestor) {
    return (
      <NewFundTx
        onClose={() => {
          setIsAddInvestor(false);
          setInvestorId(null);
        }}
        fundId={fund.id}
        userId={investorId}
      />
    );
  }

  const columns = [
    {
      title: "User",
      key: "user",
      render: ({ user }) =>
        `${get(user, "firstName", "")} ${get(user, "lastName", "")}`,
    },
    {
      title: "Units",
      key: "units",
      dataIndex: "units",
    },
    {
      title: "Action",
      key: "action",
      render: ({ user }) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            setIsAddInvestor(true);
            setInvestorId(user.id);
          }}
        />
      ),
    },
  ];
  return (
    <div className="fund-users">
      <PageHeader
        title="Back"
        onBack={onClose}
        extra={[
          <Button
            type="primary"
            onClick={() => {
              setIsAddInvestor(true);
            }}
          >
            New Investor
          </Button>,
        ]}
      />
      <div className="content">
        <Title level={3}>
          {fund.name} - Total {fund.units} Units - {unitUsed} Units Used
        </Title>
        <Table columns={columns} dataSource={fundUsers} />
      </div>
    </div>
  );
};

export default FundUsers;
