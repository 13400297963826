import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { siderCollapsedSelector, toggleSider } from "../../slices/ui.slice";
import {
  getUser,
  isAdminSelector,
  logout,
  userSelector,
} from "../../slices/auth.slice";

// UI
import { Avatar, Layout, Menu, Space, Typography } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  PieChartOutlined,
  LogoutOutlined,
  FundOutlined,
  SettingOutlined,
  RadarChartOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import "./Container.scss";
import { loadPrices } from "../../slices/coins.slice";
import { get } from "lodash";
import { Link } from "react-router-dom";

const { Header, Sider, Content } = Layout;
const getLinks = (isAdmin) => {
  let links = [];
  if (isAdmin) {
    links = links.concat(["/farms", "/coins", "/admin"]);
  } else {
    links = links.concat(["/fund", "/fundTxes", "/statements"]);
  }
  links = links.concat(["/settings"]);
  return links;
};

const Container = (props) => {
  const pathname = window.location.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const collapsed = useSelector(siderCollapsedSelector);
  const user = useSelector(userSelector);
  const isAdmin = useSelector(isAdminSelector);
  const links = getLinks(isAdmin);

  useEffect(() => {
    dispatch(getUser());
  }, []);

  useEffect(() => {
    dispatch(loadPrices());
  }, []);

  const selectedKey = links.findIndex((link) => pathname === link) || 0;

  const toggle = () => {
    dispatch(toggleSider());
  };

  const clickMenu = ({ key }) => {
    if (key == links.length) {
      localStorage.setItem("token", "");
      dispatch(logout());
      navigate("/login", { replace: true });
      return;
    }
    navigate(links[key]);
  };

  const menuItems = links.map((link, idx) => {
    const item = getIconAndText(link);
    return (
      <Menu.Item key={String(idx)} icon={item.icon}>
        {item.text}
      </Menu.Item>
    );
  });

  return (
    <Layout id="container">
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <img
            src={`/images/peak${collapsed ? "-sm" : ""}.png`}
            alt=""
            width="80%"
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[String(selectedKey)]}
          onClick={clickMenu}
        >
          {menuItems}
          <Menu.Item
            key={String(links.length)}
            icon={<LogoutOutlined />}
            danger={true}
            style={{
              position: "absolute",
              bottom: 30,
            }}
          >
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
          <Link to="/settings">
            <Space style={{ float: "right", paddingRight: 20 }}>
              <Avatar icon={<UserOutlined />} src={get(user, "image")} />
              <Typography.Text>
                {get(user, "firstName")} {get(user, "lastName")}
              </Typography.Text>
            </Space>
          </Link>
        </Header>
        {props.noBg ? (
          <div className={props.className}>{props.children}</div>
        ) : (
          <Content
            className={`site-layout-background ${props.className || ""}`}
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
            }}
          >
            {props.children}
          </Content>
        )}
      </Layout>
    </Layout>
  );
};

export default Container;

const getIconAndText = (path) => {
  switch (path) {
    case "/team":
      return { icon: <UserOutlined />, text: "Team" };
    case "/portfolio":
      return { icon: <PieChartOutlined />, text: "Portfolio" };
    case "/settings":
      return { icon: <SettingOutlined />, text: "Settings" };
    case "/fund":
      return { icon: <FundOutlined />, text: "Fund" };
    case "/farms":
      return { icon: <RadarChartOutlined />, text: "Farms" };
    case "/coins":
      return { icon: <WalletOutlined />, text: "Coins" };
    case "/admin":
      return { icon: <UserOutlined />, text: "Admin" };
    case "/fundTxes":
      return { icon: <UserOutlined />, text: "Transactions" };
    case "/statements":
      return { icon: <UserOutlined />, text: "Statements" };
    default:
      return { icon: <UserOutlined />, text: "Account" };
  }
};
