import {
  Card,
  Row,
  Col,
  Table,
  Typography,
  PageHeader,
  Tabs,
  Radio,
} from "antd";
import { Line } from "@ant-design/charts";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Navigate } from "react-router-dom";
import {
  coinDetailSelector,
  coinHistorySelector,
  loadCoinHistory,
} from "../../slices/coins.slice";
import { getNumber, numberWithCommas } from "../../utils/general";
import Container from "../commons/Container";
import "./CoinDetail.scss";

const { Text, Title } = Typography;
const { TabPane } = Tabs;

const CoinDetail = () => {
  const { id } = useParams();
  const [interval, setIntervals] = useState(24);
  const coin = useSelector(coinDetailSelector(id));
  const coinHistory = useSelector(coinHistorySelector);
  const priceHistory = coinHistory.prices || [];
  const liquidityHistory = coinHistory.liquidities || [];
  const volumeHistory = coinHistory.volumes || [];
  const dispatch = useDispatch();

  useEffect(() => {
    id && interval && dispatch(loadCoinHistory(id, interval));
  }, [id, interval]);

  if (!coin) return <Navigate to="/coins" />;

  const { pools } = coin;
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Dex",
      dataIndex: "dex",
      render: (dex) => _.capitalize(dex),
    },
    {
      title: "Liquidity",
      render: (pool) => numberWithCommas(getNumber(pool, "liquidity")),
    },
    {
      title: "Counterparty Liquidity",
      render: (pool) => numberWithCommas(getNumber(pool, "cLiquidity")),
    },
    {
      title: "Volume 24H",
      render: (pool) => numberWithCommas(getNumber(pool, "volume24h")),
    },
    {
      title: "Spot Price",
      render: (pool) => numberWithCommas(getNumber(pool, "token0.price", 8)),
    },
  ];

  const intervalSelections = (
    <Radio.Group
      onChange={(e) => setIntervals(Number(e.target.value))}
      defaultValue={interval}
    >
      <Radio.Button value={1}>1h</Radio.Button>
      <Radio.Button value={2}>2h</Radio.Button>
      <Radio.Button value={4}>4h</Radio.Button>
      <Radio.Button value={24}>24h</Radio.Button>
    </Radio.Group>
  );

  return (
    <Container noBg className="coin-detail">
      <PageHeader
        avatar={{ src: _.get(coin, "image") }}
        title={_.get(coin, "name")}
      />
      <Row gutter={16}>
        <Col span={6}>
          <Card>
            <Text>Spot Price</Text>
            <Title level={4}>
              {numberWithCommas(getNumber(coin, "price", 8))} USD
            </Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Text>Liquidity</Text>
            <Title level={4}>
              {numberWithCommas(getNumber(coin, "liquidity", 2))} USD
            </Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Text>Volume 24H</Text>
            <Title level={4}>
              {numberWithCommas(getNumber(coin, "volume24h", 2))} USD
            </Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Text>Volume 7D</Text>
            <Title level={4}>
              {numberWithCommas(getNumber(coin, "volume7d", 2))} USD
            </Title>
          </Card>
        </Col>
      </Row>
      <Card>
        <Tabs tabBarExtraContent={intervalSelections}>
          <TabPane key="price" tab="Price">
            <Line {...config(priceHistory)} />
          </TabPane>
          <TabPane key="liquidity" tab="Liquidity">
            <Line {...config(liquidityHistory)} />
          </TabPane>
          <TabPane key="volume" tab="Volume">
            <Line {...config(volumeHistory)} />
          </TabPane>
        </Tabs>
      </Card>
      <div className="bottom">
        <Table dataSource={pools} columns={columns} />
      </div>
    </Container>
  );
};

const config = (data) => ({
  data,
  height: 300,
  xField: "time",
  yField: "value",
  point: {
    size: 5,
    shape: "diamond",
  },
});
export default CoinDetail;
