import RequireAuth from "../auth/RequireAuth";

const requireAuth = (Component) => (props) => {
  return (
    <RequireAuth>
      <Component {...props} />
    </RequireAuth>
  );
};

export default requireAuth;
