import { notification } from "antd";
import axios from "axios";
import { get } from "lodash";
import { host } from "../config";

const getOptions = (method, data, params, requireAuth = true, headers) => {
  const options = {
    method,
    headers,
  };
  if (requireAuth) {
    const token = localStorage.getItem("token");
    options.headers = {
      authorization: `Bearer ${token}`,
      ...headers,
    };
  }
  if (method === "GET") {
    options.params = {
      ...data,
      ...(params || {}),
    };
  } else {
    options.data = { ...data };
    if (params) options.params = { ...params };
  }
  return options;
};

const apiRequest = ({
  url,
  method,
  data,
  params,
  requireAuth,
  headers,
  absoluteUrl,
  successMessage,
  showMessage = true,
}) => {
  const options = getOptions(method, data, params, requireAuth, headers);
  const urlPath = absoluteUrl ? url : host + url;
  return axios(urlPath, options)
    .then((res) => {
      if (res.status < 400) {
        if (method !== "GET" && showMessage) {
          notification.success({
            message: "Success",
            description: successMessage,
          });
        }
        return res.data;
      } else {
        notification.error({ description: get(res, "data.message") });
        throw new Error(get(res, "data.message"));
      }
    })
    .catch((err) => {
      if (err.response && err.response.data.code === 401) {
        localStorage.removeItem("token");
      }
      notification.error({
        message: "Error",
        description:
          (err.response && err.response.data.message) || "Something went wrong",
      });
      throw err;
    });
};

const network = {
  get: ({
    url,
    data,
    params,
    requireAuth,
    headers,
    successMessage,
    showMessage = false,
  }) =>
    apiRequest({
      url,
      method: "GET",
      data,
      params,
      requireAuth,
      headers,
      successMessage,
      showMessage,
    }),
  post: ({
    url,
    data,
    params,
    requireAuth,
    headers,
    successMessage,
    showMessage,
  }) =>
    apiRequest({
      url,
      method: "POST",
      data,
      params,
      requireAuth,
      headers,
      successMessage,
      showMessage,
    }),
  put: ({
    url,
    data,
    params,
    requireAuth,
    headers,
    successMessage,
    showMessage,
  }) =>
    apiRequest({
      url,
      method: "PUT",
      data,
      params,
      requireAuth,
      headers,
      successMessage,
      showMessage,
    }),
  patch: ({
    url,
    data,
    params,
    requireAuth,
    headers,
    successMessage,
    showMessage,
  }) =>
    apiRequest({
      url,
      method: "PATCH",
      data,
      params,
      requireAuth,
      headers,
      successMessage,
      showMessage,
    }),
  delete: ({
    url,
    data,
    params,
    requireAuth,
    headers,
    successMessage,
    showMessage,
  }) =>
    apiRequest({
      url,
      method: "DELETE",
      data,
      params,
      requireAuth,
      headers,
      successMessage,
      showMessage,
    }),
};

export default network;
