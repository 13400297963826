import { Button, Input, Typography } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { verify2FA } from "../../../slices/auth.slice";
import "./Verify2FA.scss";

const { Title } = Typography;

const Verify2FA = (props) => {
  const [code, setCode] = useState("");
  const dispatch = useDispatch();
  const clickVerify = async () => {
    await dispatch(verify2FA(code));
    props.onVerified();
  };
  return (
    <div className="verify2fa">
      <Title level={4}>Input Code from Google Authenticator app:</Title>
      <div><Input value={code} onChange={(e) => setCode(e.target.value)} /></div>
      <Button type="primary" onClick={clickVerify}>
        Verify
      </Button>
    </div>
  );
};

export default Verify2FA;
