import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import network from "../network/network";
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from "./sliceHelper";

const initialState = {
  loading: false,
  hasErrors: false,
};

// Slice
const walletsSlice = createSlice({
  name: "wallets",
  initialState,
  reducers: {
    loadWalletsRequest: (state) => {
      isLoadingRequest(state);
    },
    loadWalletsSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.wallets = payload;
    },
    loadWalletsFailed: (state) => {
      finishedLoadingFailure(state);
    },
  },
});

// Selector
export const walletsSelector = (state) => get(state, "wallets.wallets", []);

// Actions
export const { loadWalletsRequest, loadWalletsSuccess, loadWalletsFailed } =
  walletsSlice.actions;

export const loadWallets = () => async (dispatch) => {
  try {
    dispatch(loadWalletsRequest());
    const result = await network.get({
      url: "/wallets/",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadWalletsSuccess(result));
  } catch (err) {
    dispatch(loadWalletsFailed());
  }
};

export const deleteWallet = (id) => async (dispatch) => {
  try {
    dispatch(loadWalletsRequest());
    await network.delete({
      url: `/wallets/${id}`,
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadWallets());
  } catch (err) {
    dispatch(loadWalletsFailed());
  }
};

export const createWallet =
  ({ id, name, address, chain }) =>
  async (dispatch) => {
    await network.post({
      url: "/wallets/",
      data: {
        id,
        name,
        address,
        chain,
      },
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadWallets());
  };
export default walletsSlice.reducer;
