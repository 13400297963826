export const ROLES = {
  user: "user",
  admin: "admin",
  superAdmin: "superAdmin",
};

export const ROLE_NAME = {
  user: "Investor",
  admin: "Admin",
  superAdmin: "Super Admin",
};
