import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, PageHeader, Select, Space, Upload } from "antd";
import { get, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  STATEMENT_TYPE,
  STATEMENT_TYPE_NAME,
} from "../../../constants/statements";
import { investorsSelector } from "../../../slices/auth.slice";
import {
  deleteStatement,
  updateStatement,
} from "../../../slices/statement.slice";

const { Item } = Form;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 13 },
};

const NewStatement = (props) => {
  const { onClose, statement } = props;
  const [form] = Form.useForm();
  const [files, setFiles] = useState([]);
  const investors = useSelector(investorsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      ...statement,
      user: get(statement, "user.id"),
    });
  }, [form, statement]);

  const clickDelete = async () => {
    statement && (await dispatch(deleteStatement(statement.id)));
    onClose();
  };
  const onSubmit = async (data) => {
    await dispatch(
      updateStatement({
        ...data,
        file: files.length && files[0],
        id: get(statement, "id"),
      })
    );
    onClose();
  };
  return (
    <div>
      <PageHeader
        title="Back"
        onBack={onClose}
        extra={
          statement
            ? [
                <Button
                  key="delete"
                  type="primary"
                  danger
                  onClick={clickDelete}
                >
                  Delete
                </Button>,
              ]
            : []
        }
      />

      <Form {...layout} form={form} onFinish={onSubmit}>
        <Item name="title" label="Title">
          <Input />
        </Item>
        <Item name="user" label="User">
          <Select>
            {map(investors, (user) => (
              <Select.Option key={user.id} value={user.id}>
                {`${user.firstName} ${user.lastName}`}
              </Select.Option>
            ))}
          </Select>
        </Item>
        <Item name="type" label="Type">
          <Select>
            {map(STATEMENT_TYPE, (val, key) => (
              <Select.Option key={val} value={val}>
                {STATEMENT_TYPE_NAME[key]}
              </Select.Option>
            ))}
          </Select>
        </Item>
        <Form.Item label="File">
          <Space>
            <Upload
              fileList={files}
              beforeUpload={() => false}
              onChange={({ fileList }) => setFiles(fileList)}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
            {statement && (
              <a href={get(statement, "file")} target="_blank" rel="noreferrer">
                View file
              </a>
            )}
          </Space>
        </Form.Item>
        <Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Item>
      </Form>
    </div>
  );
};

export default NewStatement;
