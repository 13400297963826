import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Account from "./components/accounts/Account";
import Admin from "./components/admin/Admin";
import Login from "./components/auth/Login";
import CoinDetail from "./components/coins/CoinDetail";
import Coins from "./components/coins/Coins";
import NewCoin from "./components/admin/coins/NewCoin";
import FarmDetail from "./components/farms/FarmDetail";
import Farms from "./components/farms/Farms";
import Fund from "./components/fund/Fund";
import Portfolio from "./components/portfolio/Portfolio";
import Settings from "./components/settings/Settings";
import Page2FA from "./components/auth/Page2FA";
import GetOtp from "./components/settings/Account/GetOtp";
import ResetPass from "./components/settings/Account/ResetPass";
import InvestorFundTx from "./components/investor/InvestorFundTx";
import InvestorStatement from "./components/investor/InvestorStatement";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/" element={<Navigate to="/farms" />} />
        <Route exact path="/team" element={<Account />} />
        <Route exact path="/portfolio" element={<Portfolio />} />
        <Route exact path="/fund" element={<Fund />} />
        <Route exact path="/fundTxes" element={<InvestorFundTx />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/farms" element={<Farms />} />
        <Route exact path="/coins" element={<Coins />} />
        <Route exact path="/farms/:id" element={<FarmDetail />} />
        <Route exact path="/coins/:id" element={<CoinDetail />} />
        <Route exact path="/coins/new" element={<NewCoin />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/verify2fa" element={<Page2FA />} />
        <Route exact path="/get-otp" element={<GetOtp />} />
        <Route exact path="/reset-pass" element={<ResetPass />} />
        <Route exact path="/statements" element={<InvestorStatement />} />
      </Routes>
    </Router>
  );
}

export default App;
