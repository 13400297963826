import { Card, Form, Input, InputNumber, Select } from "antd";
import React from "react";
import { MinusCircleOutlined } from "@ant-design/icons";
import { get } from "lodash";
const { Option } = Select;
const CustomCoin = (props) => {
  const { field, remove, settings, coins } = props;

  return (
    <Card key={field.key}>
      <MinusCircleOutlined
        style={{ color: "red" }}
        onClick={() => remove(field.name)}
      />
      <Form.Item {...field} name={[field.name, "symbol"]} label="Coin">
        <Select>
          {coins.map((coin) => (
            <Option value={coin.symbol} key={coin.symbol}>
              {coin.symbol}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {get(settings, "fn") && (
        <Form.Item {...field} name={[field.name, "fn"]} label="Contract Fn">
          <Input />
        </Form.Item>
      )}
      {get(settings, "fnAddress") && (
        <Form.Item
          {...field}
          name={[field.name, "fnAddress"]}
          label="Contract Address"
        >
          <Input />
        </Form.Item>
      )}
      {get(settings, "fnAbi") && (
        <Form.Item {...field} name={[field.name, "fnAbi"]} label="Contract ABI">
          <Input />
        </Form.Item>
      )}
      {get(settings, "totalFn") && (
        <Form.Item
          {...field}
          name={[field.name, "totalFn"]}
          label="Total Supply Contract Fn"
        >
          <Input />
        </Form.Item>
      )}
      {get(settings, "totalFnAddress") && (
        <Form.Item
          {...field}
          name={[field.name, "totalFnAddress"]}
          label="Total Supply Contract Address"
        >
          <Input />
        </Form.Item>
      )}
      {get(settings, "totalFnAbi") && (
        <Form.Item
          {...field}
          name={[field.name, "totalFnAbi"]}
          label="Total Supply Contract ABI"
        >
          <Input />
        </Form.Item>
      )}
    </Card>
  );
};

export default CustomCoin;
