import { Col, PageHeader, Row, Card, Typography, Tabs } from "antd";
import { round, get } from "lodash";
import React from "react";
import FundDetail from "../settings/Funds/FundDetail";
import { numberWithCommas } from "../../utils/general";
import FundChart from "./FundChart";
import WalletPanel from "../settings/Funds/WalletPanel";
import FarmPanel from "../settings/Funds/FarmPanel";

const { Title } = Typography;

const FundView = (props) => {
  const { userFund, onClose } = props;
  console.log(userFund);
  return (
    <div>
      <PageHeader title="Back" onBack={onClose} />
      <Row gutter={16} style={{ marginInline: 20 }}>
        <Col span={6}>
          <Card>
            <Title level={5}>Your balance</Title>
            <Title level={4}>
              {numberWithCommas(round(userFund.balance, 2))}$
            </Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Title level={5}>Your units</Title>
            <Title level={4}>{numberWithCommas(userFund.units)} units</Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Title level={5}>Unit price</Title>
            <Title level={4}>{numberWithCommas(userFund.unitPrice)}$</Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Title level={5}>APY</Title>
            <Title level={4}>{userFund.apr24h}%</Title>
          </Card>
        </Col>
      </Row>

      <FundChart
        fundId={get(userFund, "fund.id")}
        userId={get(userFund, "user.id")}
      />
      {/* <div style={{ margin: 30 }}>
        {get(userFund, "wallets", [])
          .filter((wallet) => wallet.coins.length)
          .map((wallet, idx) => (
            <WalletPanel key={idx} wallet={wallet} />
          ))}
        <br />
        <Title level={4}>Farms</Title>
        <FarmPanel walletFarms={userFund.farms || []} />
      </div> */}
    </div>
  );
};

export default FundView;
