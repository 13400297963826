export const FARMS = {
  liquity: "liquity",
  av3CRV: "av3CRV",
  anchor: "anchor",
  saber: "saber",
  convex: "convex",
  frax: "frax",
};

export const FARM_COIN_TYPE = {
  normal: "normal",
  custom: "custom",
};

export const COIN_SETTINGS = {
  liquity: {
    abi: true,
    coins: {
      type: FARM_COIN_TYPE.normal,
    },
    lpTokens: {
      type: FARM_COIN_TYPE.custom,
      fn: true,
      totalFn: true,
    },
    rewardTokens: {
      type: FARM_COIN_TYPE.custom,
      fn: true,
    },
  },
  av3CRV: {
    abi: true,
    coins: {
      type: FARM_COIN_TYPE.normal,
    },
    lpTokens: {
      type: FARM_COIN_TYPE.custom,
      fn: true,
      fnAbi: true,
      fnAddress: true,
      totalFn: true,
      totalFnAbi: true,
      totalFnAddress: true,
    },
    rewardTokens: {
      type: FARM_COIN_TYPE.custom,
      fn: true,
      fnAbi: true,
      fnAddress: true,
    },
  },
  convex: {
    coins: {
      type: FARM_COIN_TYPE.normal,
    },
    lpTokens: {
      type: FARM_COIN_TYPE.custom,
      fn: true,
      fnAbi: true,
      fnAddress: true,
      totalFn: true,
      totalFnAbi: true,
      totalFnAddress: true,
    },
    rewardTokens: {
      type: FARM_COIN_TYPE.custom,
      fnAbi: true,
      fnAddress: true,
      fn: true,
    },
  },
  saber: {
    solanaQuarry: true,
    coins: {
      type: FARM_COIN_TYPE.normal,
    },
    lpTokens: {
      type: FARM_COIN_TYPE.custom,
      fnAddress: true,
    },
    rewardTokens: {
      type: FARM_COIN_TYPE.custom,
      fnAddress: true,
    },
  },
  anchor: {
    lpTokens: {
      type: FARM_COIN_TYPE.normal,
    },
  },
  frax: {
    abi: true,
    coins: {
      type: FARM_COIN_TYPE.normal,
    },
    lpTokens: {
      type: FARM_COIN_TYPE.custom,
      fn: true,
      fnAbi: true,
      fnAddress: true,
      totalFn: true,
    },
    rewardTokens: {
      type: FARM_COIN_TYPE.custom,
      fn: true,
      fnAbi: true,
      fnAddress: true,
    },
  },
};
