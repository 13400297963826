import { EditOutlined } from "@ant-design/icons";
import { Button, Table, Space, Typography, PageHeader } from "antd";
import { get } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ALERT_CATEGORY,
  ALERT_CATEGORY_NAME,
  ALERT_METRIC_NAME,
  ALERT_SETTINGS_NAME,
  ALERT_TYPE_NAME,
} from "../../../constants/alerts";
import { alertsSelector, testAlert } from "../../../slices/alert.slice";
import { includeInsensitiveStr } from "../../../utils/general";
import SearchBox from "../../commons/SearchBox";
import NewAlert from "./NewAlert";
const { Text } = Typography;

const AlertList = () => {
  const alerts = useSelector(alertsSelector);
  const [alert, setAlert] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([]);
  const filterFn = (row, searchTxt) => {
    return includeInsensitiveStr(get(row, "name"), searchTxt);
  };
  if (isEditing)
    return (
      <NewAlert
        alert={alert}
        onClose={() => {
          setIsEditing(false);
          setAlert(null);
        }}
      />
    );

  const clickTest = (id) => {
    dispatch(testAlert(id));
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (cat) => ALERT_CATEGORY_NAME[cat],
    },
    {
      title: "Object",
      key: "object",
      render: (alert) => {
        return alert.category === ALERT_CATEGORY.coin
          ? get(alert, "coin.symbol")
          : get(alert, "farm.name");
      },
    },
    {
      title: "Metric",
      dataIndex: "metric",
      key: "metric",
      render: (met) => ALERT_METRIC_NAME[met],
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => ALERT_TYPE_NAME[type],
    },
    {
      title: "Settings",
      dataIndex: "settings",
      key: "settings",
      render: (settings) => (
        <Space direction="vertical">
          {Object.keys(settings).map((key) => (
            <Text>
              {ALERT_SETTINGS_NAME[key]}: {settings[key]}
            </Text>
          ))}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (alert) => (
        <Space direction="horizontal">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setAlert(alert);
              setIsEditing(true);
            }}
          />

          <Button
            type="primary"
            onClick={() => {
              clickTest(alert.id);
            }}
          >
            Test
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        extra={[<Button onClick={() => setIsEditing(true)}>New Alert</Button>]}
      ></PageHeader>
      <SearchBox
        source={alerts}
        onFiltered={setFilterData}
        filterFn={filterFn}
      />
      <Table dataSource={filterData} columns={columns} />
    </div>
  );
};

export default AlertList;
