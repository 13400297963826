import { Button, Form, Input, PageHeader, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { createWallet, deleteWallet } from "../../../slices/wallet.slice";
import { chainsSelector } from "../../../slices/chain.slice";

const { Option } = Select;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 16 },
};

const NewWallet = (props) => {
  const { wallet, onClose } = props;
  const [form] = Form.useForm();
  const chains = useSelector(chainsSelector) || [];

  const dispatch = useDispatch();

  useEffect(() => {
    if (wallet && form) {
      form.setFieldsValue({
        name: get(wallet, "name"),
        chain: get(wallet, "chain.id"),
        address: get(wallet, "address"),
      });
    }
  }, [wallet, form]);

  const onSubmit = async (data) => {
    await dispatch(
      createWallet({
        id: get(wallet, "id"),
        name: get(data, "name"),
        chain: get(data, "chain"),
        address: get(data, "address"),
      })
    );
    onClose();
  };

  const clickDelete = async () => {
    await dispatch(deleteWallet(get(wallet, "id")));
    onClose();
  };
  return (
    <div>
      <PageHeader
        title="Back"
        onBack={() => onClose()}
        extra={
          wallet
            ? [
                <Button type="primary" danger onClick={clickDelete}>
                  Delete
                </Button>,
              ]
            : []
        }
      />
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="address" label="Address">
          <Input />
        </Form.Item>
        <Form.Item
          name="chain"
          label="Chain"
          initialValue={get(wallet, "chain.id")}
        >
          <Select>
            {chains.map((chain) => (
              <Option value={chain.id}>{chain.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewWallet;
