import React, { useEffect, useState } from "react";
import Container from "../commons/Container";
import "./Coins.scss";
import requireAuth from "../hoc/requireAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  loadTrackedCoins,
  trackedCoinsSelector,
} from "../../slices/coins.slice";
import { Table, Avatar, Space } from "antd";
import { Link } from "react-router-dom";
import { round, get } from "lodash";
import { includeInsensitiveStr, numberWithCommas } from "../../utils/general";
import SearchBox from "../commons/SearchBox";

const Coins = () => {
  const dispatch = useDispatch();
  const coins = useSelector(trackedCoinsSelector);
  const [filterCoins, setFilterCoins] = useState([]);
  const filterFn = (coin, searchTxt) => {
    return includeInsensitiveStr(get(coin, "symbol", ""), searchTxt);
  };
  useEffect(() => {
    dispatch(loadTrackedCoins());
  }, []);
  const columns = [
    {
      title: "Coin",
      key: "coin",
      render: (coin) => (
        <Space>
          <Avatar src={coin.image} />
          <Link to={`/coins/${coin.id}`}>{coin.name}</Link>
        </Space>
      ),
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Liquidity",
      dataIndex: "liquidity",
      key: "liquidity",
      render: (val) => numberWithCommas(round(val, 2)),
    },
    {
      title: "Counter Liquidity",
      dataIndex: "cLiquidity",
      key: "cLiquidity",
      render: (val) => numberWithCommas(round(val, 2)),
    },
    {
      title: "Volume 24h",
      dataIndex: "volume24h",
      key: "volume24h",
      render: (val) => numberWithCommas(round(val, 2)),
    },
    {
      title: "Spot Price",
      dataIndex: "price",
      key: "price",
      render: (val) => numberWithCommas(round(val, 8)),
    },
  ];
  return (
    <Container>
      <SearchBox
        source={coins}
        onFiltered={setFilterCoins}
        filterFn={filterFn}
      />
      <Table dataSource={filterCoins} columns={columns} />
    </Container>
  );
};

export default requireAuth(Coins);
