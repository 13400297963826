export const FUND_TX_TYPE = {
  sub: "sub",
  redemp: "redemp",
  performanceFee: "performanceFee",
  managementFee: "managementFee",
};

export const FUND_TX_TYPE_NAME = {
  sub: "Subscription",
  redemp: "Redemption",
  performanceFee: "Performance Fee",
  managementFee: "Management Fee",
};
