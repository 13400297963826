import { createSlice } from "@reduxjs/toolkit";
import network from "../network/network";
import { get } from "lodash";
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from "./sliceHelper";

const initialState = {
  loading: false,
  hasErrors: false,
};

// Slice
const farmsSlice = createSlice({
  name: "farms",
  initialState,
  reducers: {
    loadWalletFarmsRequest: (state) => {
      isLoadingRequest(state);
    },
    loadWalletFarmsSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.walletFarms = payload;
    },
    loadWalletFarmsFailed: (state) => {
      finishedLoadingFailure(state);
    },

    loadFarmDetailSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.farmDetail = payload;
    },

    loadFarmHistorySuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.farmHistory = payload;
    },

    loadFarmsSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.farms = payload;
    },
  },
});

// Selector
export const walletFarmsSelector = (state) =>
  get(state, "farms.walletFarms", []);
export const farmDetailSelector = (state) => get(state, "farms.farmDetail", []);
export const farmsSelector = (state) => get(state, "farms.farms", []);
export const farmHistorySelector = (state) =>
  get(state, "farms.farmHistory", {});

// Actions
export const {
  loadWalletFarmsRequest,
  loadWalletFarmsSuccess,
  loadWalletFarmsFailed,
  loadFarmDetailSuccess,
  loadFarmsSuccess,
  loadFarmHistorySuccess,
} = farmsSlice.actions;

export const loadWalletFarms = () => async (dispatch) => {
  try {
    dispatch(loadWalletFarmsRequest());
    const result = await network.get({
      url: "/wallet-farms",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadWalletFarmsSuccess(result));
  } catch (err) {
    dispatch(loadWalletFarmsFailed());
  }
};

export const deleteWalletFarm = (id) => async (dispatch) => {
  try {
    dispatch(loadWalletFarmsRequest());
    await network.delete({
      url: `/wallet-farms/${id}`,
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadWalletFarms());
  } catch (err) {
    dispatch(loadWalletFarmsFailed());
  }
};

export const createWalletFarm =
  ({ id, name, farmId, walletId }) =>
  async (dispatch) => {
    await network.post({
      url: "/wallet-farms/",
      data: {
        id,
        name,
        walletId,
        farmId,
      },
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadWalletFarms());
  };

export const loadFarmDetail = (id) => async (dispatch) => {
  try {
    dispatch(loadWalletFarmsRequest());
    const result = await network.get({
      url: `/wallet-farms/${id}`,
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadFarmDetailSuccess(result));
  } catch (err) {
    dispatch(loadWalletFarmsFailed());
  }
};

export const loadFarmHistory = (id, interval) => async (dispatch) => {
  try {
    dispatch(loadWalletFarmsRequest());
    const result = await network.get({
      url: `/wallet-farms/${id}/history`,
      data: { interval },
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadFarmHistorySuccess(result));
  } catch (err) {
    dispatch(loadWalletFarmsFailed());
  }
};

export const loadFarms = () => async (dispatch) => {
  try {
    dispatch(loadWalletFarmsRequest());
    const result = await network.get({
      url: "/farms",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadFarmsSuccess(result));
  } catch (err) {
    dispatch(loadWalletFarmsFailed());
  }
};

export const deleteFarm = (id) => async (dispatch) => {
  try {
    dispatch(loadWalletFarmsRequest());
    await network.delete({
      url: `/farms/${id}`,
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadFarms());
  } catch (err) {
    dispatch(loadWalletFarmsFailed());
  }
};

export const createFarm =
  ({
    id,
    name,
    chainId,
    chain,
    address,
    farmType,
    abi,
    coins,
    lpTokens,
    rewardTokens,
    solanaQuarry,
  }) =>
  async (dispatch) => {
    await network.post({
      url: "/farms/",
      data: {
        id,
        name,
        chainId,
        chain,
        address,
        farmType,
        abi,
        coins,
        lpTokens,
        rewardTokens,
        solanaQuarry,
      },
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadFarms());
  };

export default farmsSlice.reducer;
