import { Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadChains } from "../../slices/chain.slice";
import { loadCoins, loadPools } from "../../slices/coins.slice";
import { loadFarms, loadWalletFarms } from "../../slices/farms.slice";
import { loadTxs } from "../../slices/tx.slice";
import { loadWallets } from "../../slices/wallet.slice";
import { loadAlerts } from "../../slices/alert.slice";
import Container from "../commons/Container";
import AlertList from "./alerts/AlertList";
import ChainList from "./chains/ChainList";
import CoinList from "./coins/CoinList";
import FarmList from "./farms/FarmList";
import PoolList from "./pools/PoolList";
import TxList from "./txs/TxList";
import WalletFarms from "./wallet-farms/WalletFarms";
import WalletList from "./wallets/WalletList";
const { TabPane } = Tabs;

const tabs = [
  { key: "coins", label: "Coins", component: <CoinList /> },
  { key: "pools", label: "Pools", component: <PoolList /> },
  { key: "chains", label: "Chains", component: <ChainList /> },
  { key: "wallets", label: "Wallets", component: <WalletList /> },
  { key: "farms", label: "Farm Types", component: <FarmList /> },
  { key: "wallet-farms", label: "Farms", component: <WalletFarms /> },
  { key: "txs", label: "Withdraw", component: <TxList /> },
  { key: "alert", label: "Alerts", component: <AlertList /> },
];

const Admin = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadCoins());
    dispatch(loadPools());
    dispatch(loadChains());
    dispatch(loadWallets());
    dispatch(loadWalletFarms());
    dispatch(loadFarms());
    dispatch(loadTxs());
    dispatch(loadAlerts());
  }, []);
  return (
    <Container>
      <Tabs>
        {tabs.map(({ key, label, component }) => (
          <TabPane key={key} tab={label}>
            {component}
          </TabPane>
        ))}
      </Tabs>
    </Container>
  );
};

export default Admin;
