import { Card, Row, Col, Table, Typography, Space, Tabs, Radio } from "antd";
import { Line } from "@ant-design/charts";
import { get, round } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { pricesSelector } from "../../slices/coins.slice";
import {
  farmDetailSelector,
  farmHistorySelector,
  loadFarmDetail,
  loadFarmHistory,
} from "../../slices/farms.slice";
import Container from "../commons/Container";
import "./FarmDetail.scss";
import { getNumber, numberWithCommas } from "../../utils/general";
import { COIN } from "../../constants/coins";

const { TabPane } = Tabs;
const { Text, Title } = Typography;

const FarmDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [interval, setIntervals] = useState(24);
  const prices = useSelector(pricesSelector) || {};
  const farm = useSelector(farmDetailSelector);
  const farmHistory = useSelector(farmHistorySelector);
  const farmValueHistory = get(farmHistory, "farmValues", []);
  const rewardHistory = get(farmHistory, "rewards", []);
  const tvlHistory = get(farmHistory, "tvls", []);
  const coins = get(farm, "coins", []);
  const rewardTokens = get(farm, "rewardTokens", []);
  const lpTokens = get(farm, "lpTokens", []);

  useEffect(() => {
    if (id) {
      dispatch(loadFarmDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && interval) {
      dispatch(loadFarmHistory(id, interval));
    }
  }, [id, interval]);

  const coinColumns = [
    {
      title: "Token",
      dataIndex: "name",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
    },
    {
      title: "Balance",
      dataIndex: "bal",
      render: (bal) => numberWithCommas(round(bal, 5)),
    },
    {
      title: "USD",
      render: (token) => numberWithCommas(round(token.usdBal, 2)),
    },
  ];

  const intervalSelections = (
    <Radio.Group
      onChange={(e) => setIntervals(Number(e.target.value))}
      defaultValue={interval}
    >
      <Radio.Button value={1}>1h</Radio.Button>
      <Radio.Button value={2}>2h</Radio.Button>
      <Radio.Button value={4}>4h</Radio.Button>
      <Radio.Button value={24}>24h</Radio.Button>
    </Radio.Group>
  );

  return (
    <Container noBg className="farm-detail">
      <Title level={4}>{get(farm, "name")}</Title>
      <Row className="main">
        <Col className="left">
          <Card>
            <Space direction="vertical">
              <Text strong>Farm Value</Text>
              <Title level={3}>
                {numberWithCommas(getNumber(farm, "farmValue", 0))} USD
              </Title>
              <Text></Text>
            </Space>
          </Card>
          <Card>
            <Space direction="vertical">
              <Text strong>24H APR</Text>
              <Title level={3}>
                {numberWithCommas(getNumber(farm, "apr24h", 2))} %
              </Title>
              <Text>
                7D APR: {numberWithCommas(getNumber(farm, "apr7d", 2))}%
              </Text>
            </Space>
          </Card>
          <Card>
            <Space direction="vertical">
              <Text strong>24H Yield</Text>
              <Title level={3}>
                {numberWithCommas(getNumber(farm, "yield24h", 2))} USD
              </Title>
              <Text>
                7D Yield: {numberWithCommas(getNumber(farm, "yield7d", 2))} USD
              </Text>
            </Space>
          </Card>
          <Card>
            <Space direction="vertical">
              <Text strong>TVL</Text>
              <Title level={3}>
                {numberWithCommas(getNumber(farm, "tvl", 0))} USD
              </Title>
              <Text></Text>
            </Space>
          </Card>
        </Col>
        <Col className="right">
          <Card>
            <Tabs tabBarExtraContent={intervalSelections}>
              <TabPane key="farmValues" tab="Farm Values">
                <Line {...config(farmValueHistory)} />
              </TabPane>
              <TabPane key="rewards" tab="24H Yield">
                <Line {...config(rewardHistory)} />
              </TabPane>
              <TabPane key="tvls" tab="TVL">
                <Line {...config(tvlHistory)} />
              </TabPane>
            </Tabs>
          </Card>
          {coins.length > 0 && (
            <div>
              <Title level={5}>Assets</Title>
              <Table
                dataSource={coins}
                columns={coinColumns}
                pagination={false}
              />
            </div>
          )}
          {rewardTokens.length > 0 && (
            <div>
              <Title level={5}>Rewards</Title>
              <Table
                dataSource={rewardTokens}
                columns={coinColumns}
                pagination={false}
              />
            </div>
          )}
          {lpTokens.length > 0 && (
            <div>
              <Title level={5}>Liquidity Tokens</Title>
              <Table
                dataSource={lpTokens}
                columns={coinColumns}
                pagination={false}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const config = (data) => ({
  data,
  height: 300,
  xField: "time",
  yField: "value",
  point: {
    size: 5,
    shape: "diamond",
  },
});

export default FarmDetail;
