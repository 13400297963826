import { Card, PageHeader, Typography, Row, Col } from "antd";
import { round } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminFundSelector, loadAdminFund } from "../../../slices/fund.slice";
import { numberWithCommas } from "../../../utils/general";
import FundChart from "../../fund/FundChart";
import FarmPanel from "./FarmPanel";
import "./FundDetail.scss";
import WalletPanel from "./WalletPanel";

const { Title } = Typography;

const FundDetail = (props) => {
  const { onClose, fundId, navigationEnabled = true, userId } = props;
  const fund = useSelector(adminFundSelector);
  console.log(fund);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAdminFund(fundId));
  }, []);

  return (
    <div className="fund-detail">
      {navigationEnabled && (
        <>
          <PageHeader title="Back" onBack={onClose} />
          <Row gutter={16} style={{ marginInline: 20 }}>
            <Col span={5}>
              <Card>
                <Title level={5}>Total Value</Title>
                <Title level={4}>
                  {numberWithCommas(round(fund.totalVal))}$
                </Title>
              </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Title level={5}>Farm Value</Title>
                <Title level={4}>
                  {numberWithCommas(round(fund.farmVal))}$
                </Title>
              </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Title level={5}>Wallet Value</Title>
                <Title level={4}>
                  {numberWithCommas(round(fund.walletVal))}$
                </Title>
              </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Title level={5}>Total Units</Title>
                <Title level={4}>{numberWithCommas(fund.units)}</Title>
              </Card>
            </Col>
            <Col span={4}>
              <Card>
                <Title level={5}>Unit Price</Title>
                <Title level={4}>
                  {numberWithCommas(round(fund.unitPrice, 6))}$
                </Title>
              </Card>
            </Col>
          </Row>
        </>
      )}
      <FundChart fundId={fundId} userId={userId} />

      <div className="content">
        <Title level={4}>Wallets</Title>
        {fund.wallets &&
          fund.wallets
            .filter((wallet) => wallet.coins.length)
            .map((wallet, idx) => <WalletPanel key={idx} wallet={wallet} />)}
        <br />
        <Title level={4}>Farms</Title>
        <FarmPanel walletFarms={fund.farms || []} />
      </div>
    </div>
  );
};

export default FundDetail;
