import { EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Table, Space, Typography, PageHeader } from "antd";
import { get, keyBy } from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { chainsSelector } from "../../../slices/chain.slice";
import { coinsSelector } from "../../../slices/coins.slice";
import { includeInsensitiveStr } from "../../../utils/general";
import SearchBox from "../../commons/SearchBox";
import NewCoin from "./NewCoin";
const { Text } = Typography;

const CoinList = () => {
  const coins = useSelector(coinsSelector);
  const chains = useSelector(chainsSelector);
  const [coin, setCoin] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const chainByIds = keyBy(chains, "id");
  const [filterCoins, setFilterCoins] = useState([]);
  const filterFn = (coin, searchTxt) => {
    return includeInsensitiveStr(get(coin, "symbol", ""), searchTxt);
  };

  if (isEditing)
    return (
      <NewCoin
        coin={coin}
        onClose={() => {
          setIsEditing(false);
          setCoin(null);
        }}
      />
    );
  const columns = [
    {
      title: "Name",
      key: "name",
      render: (coin) => (
        <Space>
          <Avatar src={coin.image} /> <Text>{coin.name}</Text>
        </Space>
      ),
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Address",
      dataIndex: "chainAddrs",
      key: "address",
      render: (chainAddrs) => (
        <Space direction="vertical">
          {chainAddrs.map((ca) => {
            const chain = chainByIds[get(ca, "chain")];
            const addr = get(ca, "address");
            return (
              <Text key={addr}>
                {get(chain, "name")}:{" "}
                <a
                  href={`${get(chain, "explorer")}address/${addr}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {addr}
                </a>
              </Text>
            );
          })}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (coin) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            setCoin(coin);
            setIsEditing(true);
          }}
        />
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        extra={[
          <Button key="newcoin" onClick={() => setIsEditing(true)}>
            New Coin
          </Button>,
        ]}
      ></PageHeader>
      <SearchBox
        source={coins}
        onFiltered={setFilterCoins}
        filterFn={filterFn}
      />
      <Table dataSource={filterCoins} columns={columns} />
    </div>
  );
};

export default CoinList;
