import { Button, Form, Input, PageHeader, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  coinsSelector,
  createPool,
  deletePool,
} from "../../../slices/coins.slice";
import { capitalize, get, keyBy, map } from "lodash";
import { DEX, POOL_VERSION } from "../../../constants/pools";
import { chainsSelector } from "../../../slices/chain.slice";

const { Option } = Select;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 16 },
};

const NewPool = (props) => {
  const { pool, onClose } = props;
  const [form] = Form.useForm();
  const coins = useSelector(coinsSelector) || [];
  const chains = useSelector(chainsSelector);
  const [chain, setChain] = useState(get(pool, "chain"));
  const filterCoins = coins.filter((coin) => {
    const chains = map(coin.chainAddrs, "chain");
    return chains.includes(chain);
  });
  const mapCoins = keyBy(filterCoins, "symbol");

  const dispatch = useDispatch();

  useEffect(() => {
    if (pool && form) {
      form.setFieldsValue({
        name: get(pool, "name"),
        address: get(pool, "address"),
        chain: get(pool, "chain"),
      });
    }
  }, [pool, form]);

  const onSubmit = async (data) => {
    const token0 = mapCoins[get(data, "token0")];
    const token1 = mapCoins[get(data, "token1")];
    const chain = get(data, "chain");
    const getTokenInfo = (oldToken, updateToken) => {
      const chainAddr = updateToken.chainAddrs.find((ca) => ca.chain === chain);
      return {
        name: get(updateToken, "name"),
        symbol: get(updateToken, "symbol"),
        address: get(chainAddr, "address"),
        image: get(updateToken, "image"),
        chainId: get(updateToken, "chainId"),
        decimals: get(chainAddr, "decimals"),
        price: get(oldToken, "price"),
        bal: get(oldToken, "bal"),
      };
    };
    dispatch(
      createPool({
        id: get(pool, "id"),
        name: get(data, "name"),
        address: get(data, "address"),
        chain,
        dex: get(data, "dex"),
        version: get(data, "version"),
        token0: getTokenInfo(get(pool, "token0"), token0),
        token1: getTokenInfo(get(pool, "token1"), token1),
      })
    );

    onClose();
  };
  const clickDelete = async () => {
    await dispatch(deletePool(get(pool, "id")));
    onClose();
  };
  return (
    <div>
      <PageHeader
        title="Back"
        onBack={() => onClose()}
        extra={
          pool
            ? [
                <Button type="primary" danger onClick={clickDelete}>
                  Delete
                </Button>,
              ]
            : []
        }
      />
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="address" label="Address">
          <Input />
        </Form.Item>
        <Form.Item name="chain" label="Chain" initialValue={get(pool, "chain")}>
          <Select onChange={setChain}>
            {chains.map((chain) => (
              <Option value={chain.id}>{chain.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="dex" label="DEX" initialValue={get(pool, "dex")}>
          <Select>
            {Object.values(DEX).map((dex) => (
              <Option value={dex}>{capitalize(dex)}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="version"
          label="Version"
          initialValue={get(pool, "version")}
        >
          <Select>
            {Object.values(POOL_VERSION).map((version) => (
              <Option value={version}>{capitalize(version)}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="token0"
          label="Token 0"
          initialValue={get(pool, "token0.symbol")}
        >
          <Select>
            {filterCoins.map((coin) => (
              <Option value={coin.symbol}>{coin.symbol}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="token1"
          label="Token 1"
          initialValue={get(pool, "token1.symbol")}
        >
          <Select>
            {filterCoins.map((coin) => (
              <Option value={coin.symbol}>{coin.symbol}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewPool;
