import { EditOutlined } from "@ant-design/icons";
import { Button, PageHeader, Table, Typography } from "antd";
import { get } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FUND_TX_TYPE, FUND_TX_TYPE_NAME } from "../../../constants/funds";
import { fundTxsSelector } from "../../../slices/fund.slice";
import { includeInsensitiveStr } from "../../../utils/general";
import SearchBox from "../../commons/SearchBox";
import NewFundTx from "./NewFundTx";

const { Text } = Typography;

const FundTxList = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [tx, setTx] = useState();
  const fundTxs = useSelector(fundTxsSelector);
  const [filterTxs, setFilterTxs] = useState([]);

  const filterFn = (tx, searchTxt) => {
    if (!tx.user) return false;
    const { firstName, lastName } = tx.user;
    return (
      includeInsensitiveStr(firstName, searchTxt) ||
      includeInsensitiveStr(lastName, searchTxt)
    );
  };

  if (isEditing) {
    return (
      <NewFundTx
        onClose={() => {
          setIsEditing(false);
          setTx(null);
        }}
        tx={tx}
      />
    );
  }

  const columns = [
    {
      title: "Fund",
      dataIndex: "fund",
      key: "fund",
      render: (fund) => get(fund, "name"),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (user) =>
        `${get(user, "firstName", "")} ${get(user, "lastName", "")}`,
    },
    {
      title: "Amount",
      key: "amount",
      render: (tx) => {
        const isAdd = tx.type === FUND_TX_TYPE.sub;
        return (
          <Text type={isAdd ? "success" : "danger"}>
            {isAdd ? "+" : "-"} {tx.amount}
          </Text>
        );
      },
    },
    {
      title: "Type",
      key: "type",
      render: (tx) => (tx.isAdjust ? "Adjustment" : FUND_TX_TYPE_NAME[tx.type]),
    },
    {
      title: "Created",
      key: "updated",
      dataIndex: "createdAt",
      render: (time) => moment(time).format("DD MMM YY HH:mm"),
    },
    {
      title: "Action",
      key: "action",
      render: (tx) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            setIsEditing(true);
            setTx(tx);
          }}
        />
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        extra={[
          <Button key="new fundtx" onClick={() => setIsEditing(true)}>
            New Fund Tx
          </Button>,
        ]}
      />
      <SearchBox
        source={fundTxs}
        onFiltered={setFilterTxs}
        filterFn={filterFn}
      />
      <Table dataSource={filterTxs} columns={columns} />
    </div>
  );
};

export default FundTxList;
