import { Table, Typography } from "antd";
import { get } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FUND_TX_TYPE, FUND_TX_TYPE_NAME } from "../../constants/funds";
import {
  investorFundTxSelector,
  loadInvestorFundTxs,
} from "../../slices/fund.slice";
import Container from "../commons/Container";

const { Text } = Typography;

const InvestorFundTx = (props) => {
  const txs = useSelector(investorFundTxSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadInvestorFundTxs());
  }, []);
  const columns = [
    {
      key: "fund",
      dataIndex: "fund",
      title: "Fund",
      render: (fund) => get(fund, "name"),
    },
    {
      title: "Amount",
      key: "amount",
      render: (tx) => {
        const isAdd = tx.type === FUND_TX_TYPE.sub;
        return (
          <Text type={isAdd ? "success" : "danger"}>
            {isAdd ? "+" : "-"} {tx.amount}
          </Text>
        );
      },
    },
    {
      title: "Type",
      key: "type",
      render: (tx) => (tx.isAdjust ? "Adjustment" : FUND_TX_TYPE_NAME[tx.type]),
    },
    {
      title: "Note",
      key: "note",
      dataIndex: "note",
    },
    {
      title: "Created",
      key: "updated",
      dataIndex: "createdAt",
      render: (time) => moment(time).format("DD MMM YY HH:mm"),
    },
  ];
  return (
    <Container>
      <Table dataSource={txs} columns={columns} />
    </Container>
  );
};

export default InvestorFundTx;
