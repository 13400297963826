import { Button, Card, Col, Input, Row, Typography } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOtpLink } from "../../../slices/auth.slice";
import "./GetOtp.scss";

const GetOtp = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clickSubmit = async () => {
    await dispatch(getOtpLink(email));
    navigate("/login", { replace: true });
  };
  return (
    <div className="get-otp">
      <Row>
        <Col span={6} offset={8}>
          <Card>
            <Typography.Title level={4}>
              Submit your email. We will send an OTP link to your email to reset
              password:
            </Typography.Title>
            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            <Button type="primary" onClick={clickSubmit}>
              Submit
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default GetOtp;
