import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { ROLES } from "../constants/user";
import User from "../models/user";
import network from "../network/network";
import { uploadImage } from "../network/upload";
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from "./sliceHelper";

const initialState = {
  loading: false,
  hasErrors: false,
};

// Slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest: (state) => {
      isLoadingRequest(state);
    },
    loginSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      const user = User.get(payload);
      localStorage.setItem("token", user.token);
      state.user = user;
    },

    getUserSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      const user = User.get(payload);
      state.user = user;
    },
    getUsersSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.users = payload;
    },
    loginFailed: (state) => {
      finishedLoadingFailure(state);
    },
    logout: (state) => {
      state.user = null;
    },
  },
});

// Selector
export const startLoginSelector = (state) => get(state, "auth.loading");
export const loginError = (state) => get(state, "auth.hasErrors");
export const userToken = (state) => get(state, "auth.user.token");
export const userSelector = (state) => get(state, "auth.user", {});
export const usersSelector = (state) => get(state, "auth.users", []);
export const isAdminSelector = (state) =>
  get(state, "auth.user.role", ROLES.user) !== ROLES.user;
export const isSuperAdminSelector = (state) =>
  get(state, "auth.user.role", ROLES.user) === ROLES.superAdmin;
export const investorsSelector = (state) =>
  get(state, "auth.users", []).filter((user) => user.role === ROLES.user);

// Actions
export const {
  loginRequest,
  loginSuccess,
  loginFailed,
  logout,
  getUserSuccess,
  getUsersSuccess,
} = authSlice.actions;

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    const user = await network.post({
      url: "/auth/login",
      data: { email, password },
      requireAuth: false,
      showMessage: false,
    });
    dispatch(loginSuccess(user));
  } catch (err) {
    dispatch(loginFailed());
  }
};

export const getUser = () => async (dispatch) => {
  try {
    dispatch(loginRequest());
    const user = await network.get({
      url: "/auth/current",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(getUserSuccess(user));
  } catch (err) {
    dispatch(loginFailed());
  }
};

export const updateUserInfo =
  ({ firstName, lastName, file }) =>
  async (dispatch) => {
    try {
      dispatch(loginRequest());
      let image;
      if (file) image = await uploadImage({ folder: "avatar", file });

      const user = await network.post({
        url: "/auth/current",
        data: { firstName, lastName, image },
        requireAuth: true,
        showMessage: false,
      });
      dispatch(getUserSuccess(user));
    } catch (err) {
      dispatch(loginFailed());
    }
  };

export const changePassword =
  ({ oldPass, newPass }) =>
  async (dispatch) => {
    try {
      dispatch(loginRequest());
      await network.post({
        url: "/auth/change-pass",
        data: { oldPass, newPass },
        requireAuth: true,
        showMessage: true,
        successMessage: "Password changed.",
      });
    } catch (err) {
      dispatch(loginFailed());
      throw err;
    }
  };

export const getUsers = () => async (dispatch) => {
  try {
    dispatch(loginRequest());
    const users = await network.get({
      url: "/auth/users",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(getUsersSuccess(users));
  } catch (err) {
    dispatch(loginFailed());
    throw err;
  }
};

export const registerUser =
  ({ id, firstName, lastName, role, email, password }) =>
  async (dispatch) => {
    try {
      dispatch(loginRequest());
      await network.post({
        url: "/auth/register",
        data: { id, firstName, lastName, role, email, password },
        requireAuth: true,
        showMessage: false,
      });
      dispatch(getUsers());
    } catch (err) {
      dispatch(loginFailed());
      throw err;
    }
  };

export const deleteUser = (userId) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    await network.delete({
      url: "/auth/users/" + userId,
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(getUsers());
  } catch (err) {
    dispatch(loginFailed());
    throw err;
  }
};

export const enable2FA =
  ({ enabled, code }) =>
  async (dispatch) => {
    try {
      dispatch(loginRequest());
      await network.post({
        url: "/auth/2fa/enable",
        data: { enabled, code },
        requireAuth: true,
        showMessage: false,
      });
      dispatch(getUser());
    } catch (err) {
      dispatch(loginFailed());
      throw err;
    }
  };

export const verify2FA = (code) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    await network.post({
      url: "/auth/2fa/verify",
      data: { code },
      requireAuth: true,
      showMessage: false,
    });
  } catch (err) {
    dispatch(loginFailed());
    throw err;
  }
};

export const getOtpLink = (email) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    await network.post({
      url: "/auth/reset-pass",
      data: { email },
      requireAuth: true,
      showMessage: true,
      successMessage: "Link was sent to your email",
    });
  } catch (err) {
    dispatch(loginFailed());
    throw err;
  }
};

export const resetPass =
  ({ userId, otp, password }) =>
  async (dispatch) => {
    try {
      dispatch(loginRequest());
      await network.put({
        url: "/auth/reset-pass",
        data: { userId, otp, password },
        requireAuth: true,
        showMessage: true,
        successMessage: "Password was reset",
      });
    } catch (err) {
      dispatch(loginFailed());
      throw err;
    }
  };

export default authSlice.reducer;
