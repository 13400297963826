import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import network from "../network/network";
import { uploadImage } from "../network/upload";
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from "./sliceHelper";

const initialState = {
  loading: false,
  hasErrors: false,
};

// Slice
const chainsSlice = createSlice({
  name: "chains",
  initialState,
  reducers: {
    loadChainsRequest: (state) => {
      isLoadingRequest(state);
    },
    loadChainsSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.chains = payload;
    },
    loadChainsFailed: (state) => {
      finishedLoadingFailure(state);
    },
  },
});

// Selector
export const chainsSelector = (state) => get(state, "chains.chains", []);

// Actions
export const { loadChainsRequest, loadChainsSuccess, loadChainsFailed } =
  chainsSlice.actions;

export const loadChains = () => async (dispatch) => {
  try {
    dispatch(loadChainsRequest());
    const result = await network.get({
      url: "/chains/",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadChainsSuccess(result));
  } catch (err) {
    dispatch(loadChainsFailed());
  }
};

export const deleteChain = (id) => async (dispatch) => {
  try {
    dispatch(loadChainsRequest());
    await network.delete({
      url: "/chains/" + id,
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadChains());
  } catch (err) {
    dispatch(loadChainsFailed());
  }
};

export const createChain =
  ({ id, name, chainId, explorer, file }) =>
  async (dispatch) => {
    let image;
    if (file) image = await uploadImage({ folder: "chains", file });
    await network.post({
      url: "/chains/",
      data: {
        id,
        name,
        chainId,
        explorer,
        image,
      },
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadChains());
  };
export default chainsSlice.reducer;
