import { EditOutlined } from "@ant-design/icons";
import { Avatar, Button, PageHeader, Space, Table, Typography } from "antd";
import { get, keyBy } from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { chainsSelector } from "../../../slices/chain.slice";
import { swapPoolsSelector } from "../../../slices/coins.slice";
import { includeInsensitiveStr } from "../../../utils/general";
import SearchBox from "../../commons/SearchBox";
import NewPool from "./NewPool";
const { Text } = Typography;

const PoolList = () => {
  const [pool, setPool] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const pools = useSelector(swapPoolsSelector);
  const chains = useSelector(chainsSelector);
  const chainByIds = keyBy(chains, "id");
  const [filterPools, setFilterPools] = useState([]);
  const filterFn = (pool, searchTxt) => {
    return (
      includeInsensitiveStr(get(pool, "token0.symbol"), searchTxt) ||
      includeInsensitiveStr(get(pool, "token1.symbol"), searchTxt)
    );
  };

  if (isEditing)
    return (
      <NewPool
        pool={pool}
        onClose={() => {
          setIsEditing(false);
          setPool(null);
        }}
      />
    );
  const columns = [
    {
      title: "Name",
      key: "name",
      render: (pool) => (
        <Space>
          <Avatar src={get(pool, "token0.image")} />
          <Avatar src={get(pool, "token1.image")} /> <Text>{pool.name}</Text>
        </Space>
      ),
    },
    {
      title: "DEX",
      dataIndex: "dex",
      key: "dex",
    },
    {
      title: "Address",
      key: "address",
      render: (pool) => {
        const chain = chainByIds[pool.chain];
        return (
          <a
            href={`${get(chain, "explorer")}address/${pool.address}`}
            target="_blank"
            rel="noreferrer"
          >
            {pool.address}
          </a>
        );
      },
    },
    {
      title: "Token 0",
      key: "token0",
      render: (pool) => get(pool, "token0.symbol"),
    },
    {
      title: "Token 1",
      key: "token1",
      render: (pool) => get(pool, "token1.symbol"),
    },
    {
      title: "Action",
      key: "action",
      render: (pool) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            setPool(pool);
            setIsEditing(true);
          }}
        />
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        extra={[
          <Button key="newpool" onClick={() => setIsEditing(true)}>
            New Pool
          </Button>,
        ]}
      ></PageHeader>
      <SearchBox
        source={pools}
        onFiltered={setFilterPools}
        filterFn={filterFn}
      />
      <Table dataSource={filterPools} columns={columns} />
    </div>
  );
};

export default PoolList;
