import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import network from "../network/network";
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from "./sliceHelper";

const initialState = {
  loading: false,
  hasErrors: false,
};

// Slice
const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    loadAlertsRequest: (state) => {
      isLoadingRequest(state);
    },
    loadAlertsSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.alerts = payload;
    },
    loadAlertsFailed: (state) => {
      finishedLoadingFailure(state);
    },
  },
});

// Selector
export const alertsSelector = (state) => get(state, "alerts.alerts", []);

// Actions
export const { loadAlertsRequest, loadAlertsSuccess, loadAlertsFailed } =
  alertsSlice.actions;

export const loadAlerts = () => async (dispatch) => {
  try {
    dispatch(loadAlertsRequest());
    const result = await network.get({
      url: "/alerts/",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadAlertsSuccess(result));
  } catch (err) {
    dispatch(loadAlertsFailed());
  }
};

export const deleteAlert = (id) => async (dispatch) => {
  try {
    dispatch(loadAlertsRequest());
    await network.delete({
      url: "/alerts/" + id,
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadAlerts());
  } catch (err) {
    dispatch(loadAlertsFailed());
  }
};

export const testAlert = (id) => async (dispatch) => {
  try {
    dispatch(loadAlertsRequest());
    await network.post({
      url: "/alerts/" + id + "/test",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
  } catch (err) {
    dispatch(loadAlertsFailed());
  }
};

export const createAlert =
  ({ id, name, category, coin, farm, type, settings, metric }) =>
  async (dispatch) => {
    await network.post({
      url: "/alerts/",
      data: {
        id,
        name,
        category,
        coin,
        farm,
        type,
        settings,
        metric,
      },
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadAlerts());
  };
export default alertsSlice.reducer;
