import { createSlice, createSelector } from "@reduxjs/toolkit";
import _, { get } from "lodash";
import network from "../network/network";
import { uploadImage } from "../network/upload";
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from "./sliceHelper";

const initialState = {
  loading: false,
  hasErrors: false,
};

// Slice
const coinsSlice = createSlice({
  name: "coins",
  initialState,
  reducers: {
    loadSwapPoolsRequest: (state) => {
      isLoadingRequest(state);
    },
    loadSwapPoolsSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.swapPools = payload;
    },
    loadCoinsSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.coins = payload;
    },
    loadTrackedCoinsSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.trackedCoins = payload;
    },
    loadCoinHistorySuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.coinHistory = payload;
    },
    loadSwapPoolsFailed: (state) => {
      finishedLoadingFailure(state);
    },

    loadPricesRequest: (state) => {
      isLoadingRequest(state);
    },
    loadPricesSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.prices = payload;
    },
    loadPricesFailed: (state) => {
      finishedLoadingFailure(state);
    },
  },
});

// Selector
export const swapPoolsSelector = (state) => get(state, "coins.swapPools", []);
export const pricesSelector = (state) => get(state, "coins.prices", []);
export const trackedCoinsSelector = (state) =>
  get(state, "coins.trackedCoins", []);
export const coinsSelector = (state) => get(state, "coins.coins", []);
export const coinDetailSelector = (id) => (state) =>
  _.find(state.coins.trackedCoins, { id });
export const coinHistorySelector = (state) =>
  _.get(state, "coins.coinHistory", {});

// Actions
export const {
  loadSwapPoolsRequest,
  loadSwapPoolsSuccess,
  loadSwapPoolsFailed,
  loadPricesRequest,
  loadPricesSuccess,
  loadPricesFailed,
  loadCoinsSuccess,
  loadTrackedCoinsSuccess,
  loadCoinHistorySuccess,
} = coinsSlice.actions;

export const loadTrackedCoins = () => async (dispatch) => {
  try {
    dispatch(loadSwapPoolsRequest());
    const result = await network.get({
      url: "/coins/tracked",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadTrackedCoinsSuccess(result));
  } catch (err) {
    dispatch(loadSwapPoolsFailed());
  }
};

export const loadCoins = () => async (dispatch) => {
  try {
    dispatch(loadSwapPoolsRequest());
    const result = await network.get({
      url: "/coins/",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadCoinsSuccess(result));
  } catch (err) {
    dispatch(loadSwapPoolsFailed());
  }
};

export const loadCoinHistory = (id, interval) => async (dispatch) => {
  try {
    dispatch(loadSwapPoolsRequest());
    const result = await network.get({
      url: `/coins/${id}/history`,
      data: { interval },
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadCoinHistorySuccess(result));
  } catch (err) {
    dispatch(loadSwapPoolsFailed());
  }
};

export const deleteCoin = (id) => async (dispatch) => {
  try {
    dispatch(loadSwapPoolsRequest());
    await network.delete({
      url: `/coins/${id}`,
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadCoins());
  } catch (err) {
    dispatch(loadSwapPoolsFailed());
  }
};

export const loadPools = () => async (dispatch) => {
  try {
    dispatch(loadSwapPoolsRequest());
    const result = await network.get({
      url: "/pool-swap",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadSwapPoolsSuccess(result));
  } catch (err) {
    dispatch(loadSwapPoolsFailed());
  }
};

export const deletePool = (id) => async (dispatch) => {
  try {
    dispatch(loadSwapPoolsRequest());
    await network.delete({
      url: `/pool-swap/${id}`,
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadPools());
  } catch (err) {
    dispatch(loadSwapPoolsFailed());
  }
};

export const loadPrices = () => async (dispatch) => {
  try {
    dispatch(loadPricesRequest());
    const result = await network.get({
      url: "/coins/prices",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadPricesSuccess(result));
  } catch (err) {
    dispatch(loadPricesFailed());
  }
};

export const createCoin =
  ({
    name,
    symbol,
    address,
    decimals,
    tracked,
    file,
    provider,
    related = [],
    pools = [],
    price = 0,
    chainAddrs = [],
  }) =>
  async (dispatch) => {
    let image;
    if (file) image = await uploadImage({ folder: "coins", file });

    await network.post({
      url: "/coins",
      data: {
        name,
        symbol,
        address,
        decimals,
        tracked,
        image,
        provider,
        related,
        pools,
        price,
        chainAddrs,
      },
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadCoins());
  };

export const createPool =
  ({ id, name, address, chainId, token0, token1, dex, version, chain }) =>
  async (dispatch) => {
    await network.post({
      url: "/pool-swap",
      data: {
        id,
        name,
        address,
        chainId,
        dex,
        version,
        token0,
        token1,
        chain,
      },
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadPools());
  };
export default coinsSlice.reducer;
