import _ from "lodash";

export const getNumber = (obj, keyPath, decimal = 2) => {
  return _.round(_.get(obj, keyPath, 0), decimal);
};

export const numberWithCommas = (x) => {
  if (!x) return "0";
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const includeInsensitiveStr = (str1, str2) => {
  return (str1 || "").toLowerCase().includes(str2.toLowerCase());
};
