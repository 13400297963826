import React, { useEffect, useState } from "react";
import { Table, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { round, get, divide } from "lodash";
import Container from "../commons/Container";
import requireAuth from "../hoc/requireAuth";
import { loadWalletFarms, walletFarmsSelector } from "../../slices/farms.slice";
import { includeInsensitiveStr, numberWithCommas } from "../../utils/general";
import "./Farms.scss";
import SearchBox from "../commons/SearchBox";

const { Link } = Typography;

const Farms = () => {
  const dispatch = useDispatch();
  const farms = useSelector(walletFarmsSelector);
  const [filterFarms, setFilterFarms] = useState([]);
  useEffect(() => {
    dispatch(loadWalletFarms());
  }, []);

  const filterFn = (farm, searchTxt) => {
    return includeInsensitiveStr(get(farm, "name", ""), searchTxt);
  };
  const dataSources = filterFarms.map((farm) => {
    const lpTokens = (farm.lpTokens || []).map(
      (token) =>
        `${numberWithCommas(round(get(token, "bal"), 5))} ${token.symbol}`
    );
    const lpToken = farm.lpTokens[0];
    const proportion = divide(
      get(lpToken, "bal", 0),
      get(lpToken, "totalBal", 0)
    );
    const coins = farm.farm.coins.map(
      (coin) =>
        `${numberWithCommas(round(coin.bal * proportion, 5))} ${coin.symbol}`
    );
    const rewardTokens = farm.rewardTokens.map(
      (token) => `${numberWithCommas(round(token.bal, 5))} ${token.symbol}`
    );

    const totalBalUsd = farm.farmValue;

    return {
      name: farm.name,
      id: farm.id,
      lpTokens,
      coins,
      rewardTokens,
      balance: numberWithCommas(round(totalBalUsd, 2)),
    };
  });
  const columns = [
    {
      title: "Farm",
      key: "farm",
      render: (farm) => <Link href={`/farms/${farm.id}`}>{farm.name}</Link>,
    },
    {
      title: "Lp Tokens",
      dataIndex: "lpTokens",
      key: "lpTokens",
      render: (tokens) => tokens.map((token) => <div key={token}>{token}</div>),
    },
    {
      title: "Coins",
      dataIndex: "coins",
      key: "coins",
      render: (coins) => coins.map((coin) => <div key={coin}>{coin}</div>),
    },
    {
      title: "Reward Tokens",
      dataIndex: "rewardTokens",
      key: "rewardTokens",
      render: (tokens) => tokens.map((token) => <div key={token}>{token}</div>),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (bal) => <div>{bal} USD</div>,
    },
  ];
  return (
    <Container>
      <SearchBox
        source={farms}
        onFiltered={setFilterFarms}
        filterFn={filterFn}
      />
      <Table dataSource={dataSources} columns={columns} />
    </Container>
  );
};

export default requireAuth(Farms);
