export const DEX = {
  pancake: "pancake",
  uni: "uni",
  sushi: "sushi",
  curve: "curve",
};

export const POOL_VERSION = {
  v1: "v1",
  v2: "v2",
  v3: "v3",
  factory: "factory",
};
