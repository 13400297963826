import { Button, Form, Input, PageHeader, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import {
  createWalletFarm,
  deleteWalletFarm,
  farmsSelector,
} from "../../../slices/farms.slice";
import { walletsSelector } from "../../../slices/wallet.slice";

const { Option } = Select;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 16 },
};

const NewWalletFarm = (props) => {
  const { walletFarm, onClose } = props;
  const [form] = Form.useForm();
  const farms = useSelector(farmsSelector) || [];
  const wallets = useSelector(walletsSelector) || [];

  const dispatch = useDispatch();

  useEffect(() => {
    if (walletFarm && form) {
      form.setFieldsValue({
        name: get(walletFarm, "name"),
        farmId: get(walletFarm, "farm.id"),
        walletId: get(walletFarm, "wallet.id"),
      });
    }
  }, [walletFarm, form]);

  const onSubmit = async (data) => {
    await dispatch(
      createWalletFarm({
        id: get(walletFarm, "id"),
        name: get(data, "name"),
        farmId: get(data, "farmId"),
        walletId: get(data, "walletId"),
      })
    );
    onClose();
  };

  const clickDelete = async () => {
    await dispatch(deleteWalletFarm(get(walletFarm, "id")));
    onClose();
  };
  return (
    <div>
      <PageHeader
        title="Back"
        onBack={() => onClose()}
        extra={
          walletFarm
            ? [
                <Button type="primary" danger onClick={clickDelete}>
                  Delete
                </Button>,
              ]
            : []
        }
      />
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item
          name="farmId"
          label="Farm"
          initialValue={get(walletFarm, "farm.id")}
        >
          <Select>
            {farms.map((farm) => (
              <Option value={farm.id}>{farm.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="walletId"
          label="Wallet"
          initialValue={get(walletFarm, "wallet.id")}
        >
          <Select>
            {wallets.map((wallet) => (
              <Option value={wallet.id}>{wallet.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewWalletFarm;
