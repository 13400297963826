import {
  Button,
  Card,
  Col,
  Input,
  notification,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPass } from "../../../slices/auth.slice";
import "./ResetPass.scss";

const ResetPass = () => {
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const userId = searchParams.get("u");
  const otp = searchParams.get("c");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clickSubmit = async () => {
    if (newPass !== confirmPass)
      return notification.error({ description: "Passwords don't match" });
    await dispatch(resetPass({ userId, otp, password: newPass }));
    navigate("/login", { replace: true });
  };
  return (
    <div style={{ marginTop: 40 }} className="reset-pass">
      <Row>
        <Col span={5} offset={8}>
          <Card className="content">
            <Typography.Title level={3}>Reset Password</Typography.Title>
            <Typography.Text>Input your new password:</Typography.Text>
            <Input.Password
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
            />
            <Input.Password
              value={confirmPass}
              onChange={(e) => setConfirmPass(e.target.value)}
            />
            <Button type="primary" onClick={clickSubmit}>
              Submit
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPass;
