import { Form, PageHeader, Input, Button, Select, Checkbox } from "antd";
import { get, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ROLES, ROLE_NAME } from "../../../constants/user";
import { deleteUser, registerUser } from "../../../slices/auth.slice";

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 16 },
};

const NewUser = (props) => {
  const { onClose, user } = props;
  const [form] = Form.useForm();
  const [resetPass, setResetPass] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user && form) {
      form.setFieldsValue(user);
    }
  }, [user, form]);

  const onSubmit = async (data) => {
    const obj = user ? { ...data, id: user.id } : data;
    await dispatch(registerUser(obj));
    onClose();
  };

  const clickDelete = async () => {
    await dispatch(deleteUser(user.id));
    onClose();
  };
  return (
    <div>
      <PageHeader
        title="Back"
        onBack={() => onClose()}
        extra={
          user
            ? [
                <Button type="primary" danger onClick={clickDelete}>
                  Delete
                </Button>,
              ]
            : []
        }
      />
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item name="firstName" label="First Name">
          <Input />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name">
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input />
        </Form.Item>
        <Form.Item name="role" label="Role" initialValue={get(user, "role")}>
          <Select>
            {map(ROLES, (role) => (
              <Select.Option value={role}>{ROLE_NAME[role]}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        {user && (
          <Form.Item label="Reset password">
            <Checkbox onChange={() => setResetPass(!resetPass)} />
          </Form.Item>
        )}

        {(!user || resetPass) && (
          <Form.Item name="password" label="Password">
            <Input />
          </Form.Item>
        )}

        <Form.Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewUser;
