import { EditOutlined } from "@ant-design/icons";
import { Button, PageHeader, Table } from "antd";
import { get } from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { walletsSelector } from "../../../slices/wallet.slice";
import { includeInsensitiveStr } from "../../../utils/general";
import SearchBox from "../../commons/SearchBox";
import NewWallet from "./NewWallet";

const WalletList = () => {
  const wallets = useSelector(walletsSelector) || [];
  const [wallet, setWallet] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const filterFn = (row, searchTxt) => {
    return includeInsensitiveStr(get(row, "name"), searchTxt);
  };
  if (isEditing)
    return (
      <NewWallet
        wallet={wallet}
        onClose={() => {
          setIsEditing(false);
          setWallet(null);
        }}
      />
    );
  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Chain",
      key: "chain",
      render: (wallet) => {
        return get(wallet, "chain.name");
      },
    },
    {
      title: "Address",
      key: "address",
      render: (wallet) => {
        return (
          <a
            href={`${get(wallet, "chain.explorer")}address/${wallet.address}`}
            target="_blank"
            rel="noreferrer"
          >
            {wallet.address}
          </a>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (wallet) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            setWallet(wallet);
            setIsEditing(true);
          }}
        />
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        extra={[<Button onClick={() => setIsEditing(true)}>New Wallet</Button>]}
      ></PageHeader>
      <SearchBox
        source={wallets}
        onFiltered={setFilterData}
        filterFn={filterFn}
      />
      <Table dataSource={filterData} columns={columns} />
    </div>
  );
};

export default WalletList;
