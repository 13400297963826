import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDashboard } from "../../slices/account.slice";
import Container from "../commons/Container";
import requireAuth from "../hoc/requireAuth";

const Account = (props) => {
  console.log("account");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboard());
  }, []);
  return <Container>Account</Container>;
};

export default requireAuth(Account);
