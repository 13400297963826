import { UploadOutlined } from "@ant-design/icons";
import { Form, PageHeader, Space, Input, Button, Avatar, Upload } from "antd";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo, userSelector } from "../../../slices/auth.slice";

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 16 },
};

const EditUser = (props) => {
  const { onBack } = props;
  const [form] = Form.useForm();
  const [files, setFiles] = useState([]);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user && form) {
      form.setFieldsValue({
        firstName: get(user, "firstName"),
        lastName: get(user, "lastName"),
      });
    }
  }, [user, form]);

  const onSubmit = async (data) => {
    const obj = {
      firstName: get(data, "firstName"),
      lastName: get(data, "lastName"),
      file: files.length && files[0],
    };
    await dispatch(updateUserInfo(obj));
    onBack();
  };
  return (
    <div>
      <PageHeader title="Back" onBack={() => onBack()} />
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item name="firstName" label="First Name">
          <Input />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name">
          <Input />
        </Form.Item>
        <Form.Item name="image" label="Image">
          <Space>
            <Avatar src={get(user, "image")} />
            <Upload
              fileList={files}
              beforeUpload={() => false}
              onChange={({ fileList }) => setFiles(fileList)}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Space>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditUser;
