import { EditOutlined } from "@ant-design/icons";
import { get } from "lodash";
import { Button, PageHeader, Space, Table, Typography, Avatar } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { chainsSelector } from "../../../slices/chain.slice";
import { includeInsensitiveStr } from "../../../utils/general";
import NewChain from "./NewChain";
import SearchBox from "../../commons/SearchBox";
const { Text } = Typography;

const ChainList = () => {
  const chains = useSelector(chainsSelector) || [];
  const [chain, setChain] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const filterFn = (row, searchTxt) => {
    return includeInsensitiveStr(get(row, "name"), searchTxt);
  };
  if (isEditing)
    return (
      <NewChain
        chain={chain}
        onClose={() => {
          setIsEditing(false);
          setChain(null);
        }}
      />
    );
  const columns = [
    {
      title: "Name",
      key: "name",
      render: (chain) => (
        <Space>
          <Avatar src={chain.image} /> <Text>{chain.name}</Text>
        </Space>
      ),
    },
    {
      title: "Chain ID",
      dataIndex: "chainId",
      key: "chainId",
    },
    {
      title: "Explorer",
      dataIndex: "explorer",
      key: "explorer",
      render: (exp) => (
        <a href={exp} target="_blank" rel="noreferrer">
          {exp}
        </a>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (chain) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            setChain(chain);
            setIsEditing(true);
          }}
        />
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        extra={[
          <Button key="newchain" onClick={() => setIsEditing(true)}>
            New Chain
          </Button>,
        ]}
      ></PageHeader>
      <SearchBox
        source={chains}
        onFiltered={setFilterData}
        filterFn={filterFn}
      />
      <Table dataSource={filterData} columns={columns} />
    </div>
  );
};

export default ChainList;
