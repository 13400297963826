import { P } from "@antv/g2plot";
import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import network from "../network/network";
import { uploadImage } from "../network/upload";

const initialState = {
  statements: [],
};

const statementSlice = createSlice({
  name: "statements",
  initialState,
  reducers: {
    loadStatementsSuccess: (state, { payload }) => {
      state.statements = payload;
    },
    loadInvestorStatementsSuccess: (state, { payload }) => {
      state.investorStatements = payload;
    },
  },
});

export const statementsSelector = (state) =>
  get(state, "statements.statements", []);
export const investorStatementsSelector = (state) =>
  get(state, "statements.investorStatements", []);

export const { loadStatementsSuccess, loadInvestorStatementsSuccess } =
  statementSlice.actions;

export const loadStatements = () => async (dispatch) => {
  const result = await network.get({
    url: "/statements",
    data: {},
    requireAuth: true,
  });
  dispatch(loadStatementsSuccess(result));
};

export const updateStatement = (data) => async (dispatch) => {
  let filePath;
  if (data.file) {
    filePath = await uploadImage({
      folder: "statement",
      file: data.file,
      fileType: "application/pdf",
    });
  }

  await network.post({
    url: "/statements",
    data: {
      ...data,
      file: filePath,
    },
  });
  await dispatch(loadStatements());
};

export const deleteStatement = (id) => async (dispatch) => {
  await network.delete({
    url: "/statements/" + id,
    data: {},
  });
  await dispatch(loadStatements());
};

export const loadInvestorStatements = () => async (dispatch) => {
  const result = await network.get({
    url: "/statements/investor",
    data: {},
    requireAuth: true,
  });
  dispatch(loadInvestorStatementsSuccess(result));
};

export default statementSlice.reducer;
