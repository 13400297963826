import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
} from "antd";
import { get, map } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FUND_TX_TYPE, FUND_TX_TYPE_NAME } from "../../../constants/funds";
import { ROLES } from "../../../constants/user";
import { usersSelector } from "../../../slices/auth.slice";
import {
  createFundTx,
  deleteFundTx,
  fundsSelector,
} from "../../../slices/fund.slice";

const { Item } = Form;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 13 },
};

const NewFundTx = (props) => {
  const { onClose, tx, fundId, userId } = props;
  const [form] = Form.useForm();
  const funds = useSelector(fundsSelector);
  const users = useSelector(usersSelector);
  const investors = users.filter((user) => user.role === ROLES.user);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      ...tx,
      user: get(tx, "user.id") || userId,
      fund: get(tx, "fund.id") || fundId,
    });
  }, [form, tx]);

  const clickDelete = async () => {
    await dispatch(deleteFundTx(tx.id));
    onClose();
  };
  const onSubmit = async (data) => {
    await dispatch(
      createFundTx({
        ...data,
        id: get(tx, "id"),
      })
    );
    onClose();
  };
  return (
    <div>
      <PageHeader
        title="Back"
        onBack={onClose}
        extra={
          tx
            ? [
                <Button type="primary" danger onClick={clickDelete}>
                  Delete
                </Button>,
              ]
            : []
        }
      />

      <Form {...layout} form={form} onFinish={onSubmit}>
        <Item name="fund" label="Fund">
          <Select>
            {map(funds, (fund) => (
              <Select.Option value={fund.id}>{fund.name}</Select.Option>
            ))}
          </Select>
        </Item>
        <Item name="user" label="User">
          <Select>
            {map(investors, (user) => (
              <Select.Option value={user.id}>
                {`${user.firstName} ${user.lastName}`}
              </Select.Option>
            ))}
          </Select>
        </Item>
        <Item name="type" label="Type">
          <Select>
            {map(FUND_TX_TYPE, (val, key) => (
              <Select.Option value={val}>
                {FUND_TX_TYPE_NAME[key]}
              </Select.Option>
            ))}
          </Select>
        </Item>
        <Item name="isAdjust" valuePropName="checked" label="Is Adjustment">
          <Checkbox />
        </Item>
        <Item name="amount" label="Units">
          <InputNumber />
        </Item>
        <Item name="note" label="Note">
          <Input.TextArea rows={4} />
        </Item>

        <Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Item>
      </Form>
    </div>
  );
};

export default NewFundTx;
