import React, { useEffect, useState } from "react";
import Container from "../commons/Container";
import "./Fund.scss";
import requireAuth from "../hoc/requireAuth";
import { useDispatch, useSelector } from "react-redux";
import { fundUsersSelector, loadFundsForUser } from "../../slices/fund.slice";
import { get, round } from "lodash";
import { Button, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import FundView from "./FundView";
import { numberWithCommas } from "../../utils/general";

const Fund = (props) => {
  const [isView, setIsView] = useState(false);
  const [userFund, setUserFund] = useState();
  const dispatch = useDispatch();
  const fundUsers = useSelector(fundUsersSelector);

  useEffect(() => {
    dispatch(loadFundsForUser());
  }, []);

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "fund",
      render: (fund) => get(fund, "name"),
    },
    {
      title: "Your balance",
      key: "balance",
      dataIndex: "balance",
      render: (val) => numberWithCommas(round(val, 2)),
    },
    {
      title: "Your units",
      key: "units",
      dataIndex: "units",
      render: (val) => numberWithCommas(val),
    },
    {
      title: "Unit Price",
      key: "unitPrice",
      dataIndex: "unitPrice",
      render: (val) => numberWithCommas(val),
    },
    {
      title: "Action",
      key: "action",
      render: (userFund) => (
        <Button
          icon={<EyeOutlined />}
          onClick={() => {
            setIsView(true);
            setUserFund(userFund);
          }}
        />
      ),
    },
  ];
  return (
    <Container>
      {isView ? (
        <FundView
          userFund={userFund}
          onClose={() => {
            setUserFund(null);
            setIsView(false);
          }}
        />
      ) : (
        <Table columns={columns} dataSource={fundUsers} rowKey="id" />
      )}
    </Container>
  );
};

export default requireAuth(Fund);
