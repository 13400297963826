import { Line } from "@ant-design/charts";
import { Card, Radio, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { get } from "lodash";
import {
  fundHistorySelector,
  loadFundHistory,
  loadUserFundHistory,
  userFundHistorySelector,
} from "../../slices/fund.slice";
import { useDispatch, useSelector } from "react-redux";

const { TabPane } = Tabs;

const FundChart = (props) => {
  const { fundId, userId } = props;
  const [timeframe, setTimeframe] = useState(30);
  const fundHistory = useSelector(fundHistorySelector);
  const userFundHistory = useSelector(userFundHistorySelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fundId && timeframe) {
      if (userId) {
        dispatch(loadUserFundHistory(fundId, userId, timeframe));
      }
      dispatch(loadFundHistory(fundId, timeframe));
    }
  }, [fundId, timeframe]);

  const intervalSelections = (
    <Radio.Group
      onChange={(e) => setTimeframe(Number(e.target.value))}
      defaultValue={timeframe}
    >
      <Radio.Button value={7}>1W</Radio.Button>
      <Radio.Button value={30}>1M</Radio.Button>
      <Radio.Button value={365}>1Y</Radio.Button>
    </Radio.Group>
  );
  return (
    <Card style={{ marginInline: 25, marginTop: 20 }}>
      <Tabs tabBarExtraContent={intervalSelections}>
        <TabPane key="nav" tab={userId ? "Balance" : "NAV"}>
          <Line
            {...config(
              get(userId ? userFundHistory : fundHistory, "navs", []),
              timeframe
            )}
          />
        </TabPane>
        <TabPane key="units" tab="Units">
          <Line
            {...config(
              get(userId ? userFundHistory : fundHistory, "units", []),
              timeframe
            )}
          />
        </TabPane>
        <TabPane key="price" tab="Unit Price">
          <Line {...config(get(fundHistory, "unitPrices", []), timeframe)} />
        </TabPane>
        <TabPane key="apys" tab="APY">
          <Line {...config(get(fundHistory, "apys", []), timeframe)} />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default FundChart;

const config = (data, timeframe) => ({
  data,
  height: 300,
  xField: "time",
  yField: "value",
  point: {
    size: timeframe > 30 ? 2 : 3,
    shape: "circle",
  },
});
