import { EditOutlined } from "@ant-design/icons";
import { Button, PageHeader, Space, Table, Typography, Avatar } from "antd";
import { get } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { txsSelector } from "../../../slices/tx.slice";
import { includeInsensitiveStr } from "../../../utils/general";
import SearchBox from "../../commons/SearchBox";
import NewTx from "./NewTx";
const { Text } = Typography;

const TxList = () => {
  const txs = useSelector(txsSelector) || [];
  const [tx, setTx] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const filterFn = (row, searchTxt) => {
    return includeInsensitiveStr(get(row, "walletFarm.name"), searchTxt);
  };
  if (isEditing)
    return (
      <NewTx
        tx={tx}
        onClose={() => {
          setIsEditing(false);
          setTx(null);
        }}
      />
    );
  const columns = [
    {
      title: "Farm",
      key: "farm",
      render: (tx) => (
        <Space>
          <Text>{get(tx, "walletFarm.name")}</Text>
        </Space>
      ),
    },
    {
      title: "Tx ID",
      key: "txId",
      render: (tx) => (
        <a
          href={`${get(tx, "chain.explorer")}tx/${get(tx, "txId")}`}
          target="_blank"
          rel="noreferrer"
        >
          {get(tx, "txId", "").slice(0, 25)}...
        </a>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Coin",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (time) => moment.unix(time).format("DD MMM YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (tx) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            setTx(tx);
            setIsEditing(true);
          }}
        />
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        extra={[<Button onClick={() => setIsEditing(true)}>New Tx</Button>]}
      ></PageHeader>
      <SearchBox source={txs} onFiltered={setFilterData} filterFn={filterFn} />
      <Table dataSource={filterData} columns={columns} />
    </div>
  );
};

export default TxList;
