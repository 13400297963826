import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import network from "../network/network";
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from "./sliceHelper";

const initialState = {
  loading: false,
  hasErrors: false,
};

// Slice
const fundsSlice = createSlice({
  name: "funds",
  initialState,
  reducers: {
    loadFundsRequest: (state) => {
      isLoadingRequest(state);
    },

    loadFundsFailed: (state) => {
      finishedLoadingFailure(state);
    },
    loadFundsSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.funds = payload;
    },
    loadFundTxsSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.fundTxs = payload;
    },
    loadFundUsersSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.fundUsers = payload;
    },
    loadFundHistorySuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.fundHistory = payload;
    },
    loadUserFundHistorySuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.userFundHistory = payload;
    },
    loadAdminFundSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.adminFund = payload;
    },
    loadInvestorFundTxsSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.investorFundTxs = payload;
    },
  },
});

// Selector
export const fundsSelector = (state) => get(state, "funds.funds", []);
export const fundTxsSelector = (state) =>
  get(state, "funds.fundTxs", []).filter((tx) => tx.user);
export const fundUsersSelector = (state) => get(state, "funds.fundUsers", []);
export const fundHistorySelector = (state) =>
  get(state, "funds.fundHistory", {});
export const userFundHistorySelector = (state) =>
  get(state, "funds.userFundHistory", {});
export const adminFundSelector = (state) => get(state, "funds.adminFund", {});
export const investorFundTxSelector = (state) =>
  get(state, "funds.investorFundTxs", []);

// Actions
export const {
  loadFundsRequest,
  loadFundsSuccess,
  loadFundsFailed,
  loadFundTxsSuccess,
  loadFundUsersSuccess,
  loadFundHistorySuccess,
  loadUserFundHistorySuccess,
  loadAdminFundSuccess,
  loadInvestorFundTxsSuccess,
} = fundsSlice.actions;

export const loadFunds = () => async (dispatch) => {
  try {
    dispatch(loadFundsRequest());
    const result = await network.get({
      url: "/funds/admin",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadFundsSuccess(result));
  } catch (err) {
    dispatch(loadFundsFailed());
  }
};

export const deleteFund = (id) => async (dispatch) => {
  try {
    dispatch(loadFundsRequest());
    await network.delete({
      url: `/funds/${id}`,
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadFunds());
  } catch (err) {
    dispatch(loadFundsFailed());
  }
};

export const createFund = (data) => async (dispatch) => {
  await network.post({
    url: "/funds/admin",
    data,
    requireAuth: true,
    showMessage: false,
  });
  dispatch(loadFunds());
};

export const loadFundTxs = () => async (dispatch) => {
  try {
    dispatch(loadFundsRequest());
    const result = await network.get({
      url: "/funds/tx/",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadFundTxsSuccess(result));
  } catch (err) {
    dispatch(loadFundsFailed());
  }
};

export const deleteFundTx = (id) => async (dispatch) => {
  try {
    dispatch(loadFundsRequest());
    await network.delete({
      url: `/funds/tx/${id}`,
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadFundTxs());
  } catch (err) {
    dispatch(loadFundsFailed());
  }
};

export const createFundTx = (data) => async (dispatch) => {
  await network.post({
    url: "/funds/tx/",
    data,
    requireAuth: true,
    showMessage: false,
  });
  dispatch(loadFundTxs());
  dispatch(loadFundUsers({ fund: get(data, "fund") }));
};

export const loadFundUsers =
  ({ user, fund }) =>
  async (dispatch) => {
    try {
      dispatch(loadFundsRequest());
      const result = await network.get({
        url: "/funds/user/",
        data: { user, fund },
        requireAuth: true,
        showMessage: false,
      });
      dispatch(loadFundUsersSuccess(result));
    } catch (err) {
      dispatch(loadFundsFailed());
    }
  };

export const loadFundsForUser = () => async (dispatch) => {
  try {
    dispatch(loadFundsRequest());
    const result = await network.get({
      url: "/funds",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadFundUsersSuccess(result));
  } catch (err) {
    dispatch(loadFundsFailed());
  }
};

export const loadFundHistory = (fundId, timeframe) => async (dispatch) => {
  try {
    dispatch(loadFundsRequest());
    const result = await network.get({
      url: `/funds/${fundId}/history`,
      data: {
        timeframe,
      },
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadFundHistorySuccess(result));
  } catch (err) {
    dispatch(loadFundsFailed());
  }
};

export const loadUserFundHistory =
  (fundId, userId, timeframe) => async (dispatch) => {
    try {
      dispatch(loadFundsRequest());
      const result = await network.get({
        url: `/funds/${fundId}/history/${userId}`,
        data: {
          timeframe,
        },
        requireAuth: true,
        showMessage: false,
      });
      dispatch(loadUserFundHistorySuccess(result));
    } catch (err) {
      dispatch(loadFundsFailed());
    }
  };

export const loadAdminFund = (fundId) => async (dispatch) => {
  try {
    dispatch(loadFundsRequest());
    const result = await network.get({
      url: `/funds/${fundId}`,
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadAdminFundSuccess(result));
  } catch (err) {
    dispatch(loadFundsFailed());
  }
};

export const loadInvestorFundTxs = () => async (dispatch) => {
  try {
    dispatch(loadFundsRequest());
    const result = await network.get({
      url: `/funds/investor/tx`,
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadInvestorFundTxsSuccess(result));
  } catch (err) {
    dispatch(loadFundsFailed());
  }
};

export default fundsSlice.reducer;
