import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Space,
  PageHeader,
  Upload,
  Select,
  Card,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  coinsSelector,
  createCoin,
  deleteCoin,
  swapPoolsSelector,
} from "../../../slices/coins.slice";
import { capitalize, get } from "lodash";
import Avatar from "antd/lib/avatar/avatar";
import { PRICE_PROVIDER } from "../../../constants/coins";
import { chainsSelector } from "../../../slices/chain.slice";

const { Option } = Select;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 16 },
};

const NewCoin = (props) => {
  const { coin, onClose } = props;
  const [form] = Form.useForm();
  const [files, setFiles] = useState([]);
  const [provider, setProvider] = useState(get(coin, "provider"));
  const coins = useSelector(coinsSelector) || [];
  const pools = useSelector(swapPoolsSelector) || [];
  const chains = useSelector(chainsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (coin && form) {
      form.setFieldsValue({
        name: get(coin, "name"),
        symbol: get(coin, "symbol"),
        tracked: get(coin, "tracked"),
        chainAddrs: get(coin, "chainAddrs", []),
      });
    }
  }, [coin, form]);

  const onSubmit = async (data) => {
    await dispatch(
      createCoin({
        file: files.length && files[0],
        name: get(data, "name"),
        symbol: get(data, "symbol"),
        tracked: get(data, "tracked"),
        provider: get(data, "provider"),
        price: get(data, "price"),
        related: get(data, "related"),
        pools: get(data, "pools"),
        chainAddrs: get(data, "chainAddrs"),
      })
    );
    onClose();
  };
  const clickDelete = async () => {
    await dispatch(deleteCoin(get(coin, "id")));
    onClose();
  };
  return (
    <div>
      <PageHeader
        title="Back"
        onBack={() => onClose()}
        extra={
          coin
            ? [
                <Button type="primary" danger onClick={clickDelete}>
                  Delete
                </Button>,
              ]
            : []
        }
      />
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="symbol" label="Symbol">
          <Input />
        </Form.Item>
        <Form.Item name="image" label="Image">
          <Space>
            <Avatar src={get(coin, "image")} />
            <Upload
              fileList={files}
              beforeUpload={() => false}
              onChange={({ fileList }) => setFiles(fileList)}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Space>
        </Form.Item>
        <Form.Item
          name="tracked"
          valuePropName="checked"
          label="Track the coin"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="provider"
          label="Price Provider"
          initialValue={get(coin, "provider")}
        >
          <Select onChange={setProvider}>
            {Object.keys(PRICE_PROVIDER).map((provider) => (
              <Option value={provider}>{capitalize(provider)}</Option>
            ))}
          </Select>
        </Form.Item>
        {provider === PRICE_PROVIDER.stable && (
          <Form.Item name="price" label="Price">
            <InputNumber defaultValue={get(coin, "price", 0)} />
          </Form.Item>
        )}
        {provider === PRICE_PROVIDER.related && (
          <Form.Item
            name="related"
            label="Related Coins"
            initialValue={get(coin, "related")}
          >
            <Select mode="multiple">
              {coins.map((coin) => (
                <Option value={coin.symbol}>{coin.symbol}</Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="pools"
          label="Pools"
          initialValue={get(coin, "pools", [])}
        >
          <Select mode="multiple">
            {pools.map((pool) => (
              <Option value={pool.id}>{pool.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Chain Address">
          <Form.List name="chainAddrs">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Card key={field.key}>
                    <MinusCircleOutlined
                      style={{ color: "red" }}
                      onClick={() => remove(field.name)}
                    />

                    <Form.Item
                      {...field}
                      name={[field.name, "chain"]}
                      label="Chain"
                    >
                      <Select>
                        {chains.map((chain) => (
                          <Option
                            key={get(chain, "id")}
                            value={get(chain, "id")}
                          >
                            {get(chain, "name")}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "address"]}
                      label="Address"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "decimals"]}
                      label="Decimals"
                    >
                      <InputNumber />
                    </Form.Item>
                  </Card>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Chain Address
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewCoin;
