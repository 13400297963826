export const ALERT_CATEGORY = {
  coin: "coin",
  farm: "farm",
};

export const ALERT_TYPE = {
  outOfRange: "outOfRange",
};

export const ALERT_METRIC = {
  coin: {
    spotPrice: "spotPrice",
    liquidity: "liquidity",
    cLiquidity: "cLiquidity",
  },
  farm: {
    apr24h: "apr24h",
    farmValue: "farmValue",
  },
};

export const ALERT_SETTINGS = {
  outOfRange: {
    upper: true,
    lower: true,
  },
};

export const ALERT_CATEGORY_NAME = {
  coin: "Coin",
  farm: "Farm",
};

export const ALERT_TYPE_NAME = {
  outOfRange: "Out Of Range",
};

export const ALERT_METRIC_NAME = {
  spotPrice: "Spot Price",
  liquidity: "Liquidity",
  cLiquidity: "Counter Liquidity",
  apr24h: "24H APR",
  farmValue: "Farm Value",
};

export const ALERT_SETTINGS_NAME = {
  upper: "Upper",
  lower: "Lower",
};
