export const PRICE_PROVIDER = {
  cmc: "cmc",
  terra: "terra",
  stable: "stable",
  related: "related",
};

export const COIN = {
  aUST: "aUST",
  UST: "UST",
};
