import React, { useEffect } from "react";
import Container from "../commons/Container";
import "./Settings.scss";
import requireAuth from "../hoc/requireAuth";
import { Tabs } from "antd";
import Account from "./Account/Account";
import Users from "./Users/Users";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, isAdminSelector } from "../../slices/auth.slice";
import FundList from "./Funds/FundList";
import FundTxList from "./FundTx/FundTxList";
import { loadFunds, loadFundTxs } from "../../slices/fund.slice";
import { loadWalletFarms } from "../../slices/farms.slice";
import { loadWallets } from "../../slices/wallet.slice";
import StatementList from "./Statements/StatementList";
import { loadStatements } from "../../slices/statement.slice";
const { TabPane } = Tabs;

const getTabs = (isAdmin) => {
  let tabs = [{ key: "account", label: "Account", component: <Account /> }];
  if (isAdmin) {
    tabs = tabs.concat([
      { key: "users", label: "Users", component: <Users /> },
      { key: "funds", label: "Funds", component: <FundList /> },
      { key: "fundtx", label: "Fund Txes", component: <FundTxList /> },
      {
        key: "statements",
        label: "Investor Statements",
        component: <StatementList />,
      },
    ]);
  }
  return tabs;
};

const Settings = () => {
  const isAdmin = useSelector(isAdminSelector);
  const tabs = getTabs(isAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAdmin) return;
    dispatch(loadFunds());
    dispatch(loadWalletFarms());
    dispatch(loadWallets());
    dispatch(loadFundTxs());
    dispatch(getUsers());
    dispatch(loadStatements());
  }, []);

  return (
    <Container>
      <Tabs>
        {tabs.map(({ key, label, component }) => (
          <TabPane key={key} tab={label}>
            {component}
          </TabPane>
        ))}
      </Tabs>
    </Container>
  );
};

export default requireAuth(Settings);
