import { EditOutlined } from "@ant-design/icons";
import { Button, PageHeader, Table } from "antd";
import { get, keyBy } from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { chainsSelector } from "../../../slices/chain.slice";
import { walletFarmsSelector } from "../../../slices/farms.slice";
import { includeInsensitiveStr } from "../../../utils/general";
import SearchBox from "../../commons/SearchBox";
import NewWalletFarm from "./NewWalletFarm";

const WalletFarms = () => {
  const walletFarms = useSelector(walletFarmsSelector) || [];
  const [walletFarm, setWalletFarm] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const chains = useSelector(chainsSelector);
  const chainByIds = keyBy(chains, "chainId");
  const [filterData, setFilterData] = useState([]);
  const filterFn = (row, searchTxt) => {
    return includeInsensitiveStr(get(row, "name"), searchTxt);
  };
  if (isEditing)
    return (
      <NewWalletFarm
        walletFarm={walletFarm}
        onClose={() => {
          setIsEditing(false);
          setWalletFarm(null);
        }}
      />
    );
  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Farm",
      dataIndex: "farm",
      key: "farm",
      render: (farm) => {
        const chain = chainByIds[farm.chainId];
        return (
          <a
            href={`${get(chain, "explorer")}address/${get(farm, "address")}`}
            target="_blank"
            rel="noreferrer"
          >
            {get(farm, "name")}
          </a>
        );
      },
    },
    {
      title: "Wallet",
      dataIndex: "wallet",
      key: "wallet",
      render: (wallet) => {
        const chain = chainByIds[get(wallet, "chainId")];
        return (
          <a
            href={`${get(chain, "explorer")}address/${get(wallet, "address")}`}
            target="_blank"
            rel="noreferrer"
          >
            {get(wallet, "name")}
          </a>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (walletFarm) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            setWalletFarm(walletFarm);
            setIsEditing(true);
          }}
        />
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        extra={[<Button onClick={() => setIsEditing(true)}>New Farm</Button>]}
      ></PageHeader>
      <SearchBox
        source={walletFarms}
        onFiltered={setFilterData}
        filterFn={filterFn}
      />
      <Table dataSource={filterData} columns={columns} />
    </div>
  );
};

export default WalletFarms;
