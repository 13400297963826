import { EditOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
import { Button, PageHeader, Space, Table, Typography } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { fundsSelector } from "../../../slices/fund.slice";
import {
  includeInsensitiveStr,
  numberWithCommas,
} from "../../../utils/general";
import FundDetail from "./FundDetail";
import FundUsers from "./FundUsers";
import NewFund from "./NewFund";
import { get } from "lodash";
import SearchBox from "../../commons/SearchBox";

const FundList = () => {
  const funds = useSelector(fundsSelector);
  const [isEditing, setIsEditing] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isListUsers, setIsListUsers] = useState(false);
  const [fund, setFund] = useState();
  const [filterData, setFilterData] = useState([]);
  const filterFn = (row, searchTxt) => {
    return includeInsensitiveStr(get(row, "name"), searchTxt);
  };
  if (isEditing) {
    return (
      <NewFund
        onClose={() => {
          setIsEditing(false);
          setFund(null);
        }}
        fund={fund}
      />
    );
  }

  if (isViewing) {
    return (
      <FundDetail
        onClose={() => {
          setIsViewing(false);
          setFund(null);
        }}
        fundId={fund.id}
      />
    );
  }

  if (isListUsers) {
    return (
      <FundUsers
        onClose={() => {
          setIsListUsers(false);
          setFund(null);
        }}
        fund={fund}
      />
    );
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Wallets",
      dataIndex: "wallets",
      key: "wallets",
      render: (wallets) => (
        <Space direction="vertical">
          {wallets.map((wallet) => (
            <Typography.Text key={wallet.name}> {wallet.name}</Typography.Text>
          ))}
        </Space>
      ),
    },
    {
      title: "Farms",
      dataIndex: "farms",
      key: "farms",
      render: (farms) => (
        <Space direction="vertical">
          {farms.map((farm) => (
            <Typography.Text key={farm.name}> {farm.name}</Typography.Text>
          ))}
        </Space>
      ),
    },
    {
      title: "Total Units",
      dataIndex: "units",
      key: "units",
      render: (val) => numberWithCommas(val),
    },
    {
      title: "Action",
      key: "action",
      render: (fund) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => {
              setIsViewing(true);
              setFund(fund);
            }}
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setIsEditing(true);
              setFund(fund);
            }}
          />
          <Button
            icon={<UserOutlined />}
            onClick={() => {
              setIsListUsers(true);
              setFund(fund);
            }}
          />
        </Space>
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        extra={[
          <Button key="newcoin" onClick={() => setIsEditing(true)}>
            New Fund
          </Button>,
        ]}
      ></PageHeader>
      <SearchBox
        source={funds}
        onFiltered={setFilterData}
        filterFn={filterFn}
      />
      <Table dataSource={filterData} columns={columns} />
    </div>
  );
};

export default FundList;
