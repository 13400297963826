import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  siderCollapsed: false,
};

// Slice
const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleSider: (state) => {
      console.log("toggle");
      state.siderCollapsed = !state.siderCollapsed;
    },
  },
});

// Selector
export const siderCollapsedSelector = (state) => state.ui.siderCollapsed;

// Actions
export const { toggleSider } = uiSlice.actions;

export default uiSlice.reducer;
