import { combineReducers } from "redux";
import authReducer, { logout } from "./auth.slice";
import uiReducer from "./ui.slice";
import coinsReducer from "./coins.slice";
import farmsReducer from "./farms.slice";
import chainsReducer from "./chain.slice";
import walletsReducer from "./wallet.slice";
import txReducer from "./tx.slice";
import alertsReducer from "./alert.slice";
import fundsReducer from "./fund.slice";
import statementsReducer from "./statement.slice";

const appReducer = combineReducers({
  auth: authReducer,
  coins: coinsReducer,
  ui: uiReducer,
  farms: farmsReducer,
  chains: chainsReducer,
  wallets: walletsReducer,
  txs: txReducer,
  alerts: alertsReducer,
  funds: fundsReducer,
  statements: statementsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === logout().type) {
    return undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
