import { Button, Form, Input, InputNumber, PageHeader, Select } from "antd";
import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { walletFarmsSelector } from "../../../slices/farms.slice";
import { createFund, deleteFund } from "../../../slices/fund.slice";
import { walletsSelector } from "../../../slices/wallet.slice";

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 13 },
};

const NewFund = (props) => {
  const { onClose, fund } = props;
  const [form] = Form.useForm();
  const farms = useSelector(walletFarmsSelector);
  const wallets = useSelector(walletsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fund && form) {
      form.setFieldsValue({
        ...fund,
        farms: get(fund, "farms", []).map((farm) => farm.id),
        wallets: get(fund, "wallets", []).map((wallet) => wallet.id),
      });
    }
  }, [fund, form]);

  const onSubmit = async (data) => {
    await dispatch(
      createFund({
        ...data,
        id: get(fund, "id"),
      })
    );
    onClose();
  };

  const clickDelete = async () => {
    await dispatch(deleteFund(get(fund, "id")));
    onClose();
  };
  return (
    <div>
      <PageHeader
        title="Back"
        onBack={onClose}
        extra={
          fund && [
            <Button type="primary" danger onClick={clickDelete}>
              Delete
            </Button>,
          ]
        }
      />
      <Form form={form} {...layout} onFinish={onSubmit}>
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>

        <Form.Item name="wallets" label="Wallets" initialValue={[]}>
          <Select mode="multiple">
            {wallets.map((wallet) => (
              <Select.Option value={wallet.id}>{wallet.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="farms" label="Farms" initialValue={[]}>
          <Select mode="multiple">
            {farms.map((farm) => (
              <Select.Option value={farm.id}>{farm.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="units" label="Units">
          <InputNumber />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewFund;
