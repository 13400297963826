import { EditOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Space, Typography } from "antd";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_NAME } from "../../../constants/user";
import { getUser, userSelector } from "../../../slices/auth.slice";
import ChangePass from "./ChangePass";
import EditUser from "./EditUser";
import Security from "./Security";
import "./Account.scss";

const { Title, Text } = Typography;

const Account = () => {
  const [editInfo, setEditInfo] = useState(false);
  const [changePass, setChangePass] = useState(false);
  const [change2FA, setChange2FA] = useState(false);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUser());
  }, []);

  if (editInfo) return <EditUser onBack={() => setEditInfo(false)} />;
  if (changePass) return <ChangePass onBack={() => setChangePass(false)} />;
  if (change2FA) return <Security onBack={() => setChange2FA(false)} />;

  return (
    <div className="account">
      <Space direction="vertical" size="large">
        <Space>
          <Avatar size={70} icon={<UserOutlined />} src={get(user, "image")} />
          <Title level={2}>
            {get(user, "firstName")} {get(user, "lastName")}
          </Title>
          <Button
            icon={<EditOutlined />}
            onClick={() => setEditInfo(true)}
          ></Button>
        </Space>
        <br />

        <Space>
          <Text>Email:</Text>
          <Text strong>{get(user, "email")}</Text>
        </Space>
        <Space>
          <Text>Role:</Text>
          <Text strong>{ROLE_NAME[get(user, "role")]}</Text>
        </Space>
        <Space>
          <Text>Password:</Text>
          <Text strong>***********</Text>
          <Button
            icon={<EditOutlined />}
            onClick={() => setChangePass(true)}
          ></Button>
        </Space>
        <Space>
          <Text>Google Authenticator:</Text>
          <Text strong>{user.enable2FA ? "Enabled" : "Disabled"}</Text>
          <Button
            icon={<EditOutlined />}
            onClick={() => setChange2FA(true)}
          ></Button>
        </Space>
      </Space>
    </div>
  );
};

export default Account;
