import { Table } from "antd";
import { round } from "lodash";
import { numberWithCommas } from "../../../utils/general";

const FarmPanel = (props) => {
  const { walletFarms } = props;

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Farm Value",
      key: "assetVal",
      dataIndex: "assetVal",
      render: (val) => numberWithCommas(round(val, 2)),
    },
    {
      title: "Yield Value",
      key: "rewardVal",
      dataIndex: "rewardVal",
      render: (val) => numberWithCommas(round(val, 2)),
    },
    {
      title: "Total Value",
      key: "totalVal",
      dataIndex: "totalVal",
      render: (val) => numberWithCommas(round(val, 2)),
    },
  ];
  return <Table columns={columns} dataSource={walletFarms} />;
};
export default FarmPanel;
