import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Space,
  PageHeader,
  Upload,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { createAlert, deleteAlert } from "../../../slices/alert.slice";
import { coinsSelector } from "../../../slices/coins.slice";
import { walletFarmsSelector } from "../../../slices/farms.slice";
import { capitalize, get } from "lodash";
import {
  ALERT_CATEGORY,
  ALERT_CATEGORY_NAME,
  ALERT_METRIC,
  ALERT_METRIC_NAME,
  ALERT_SETTINGS,
  ALERT_TYPE,
  ALERT_TYPE_NAME,
} from "../../../constants/alerts";

const { Option } = Select;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 16 },
};

const NewAlert = (props) => {
  const { alert, onClose } = props;
  const [form] = Form.useForm();
  const [category, setCategory] = useState(get(alert, "category"));
  const [type, setType] = useState(get(alert, "type"));
  const coins = useSelector(coinsSelector);
  const walletFarms = useSelector(walletFarmsSelector);
  const settings = ALERT_SETTINGS[type];

  const dispatch = useDispatch();

  useEffect(() => {
    if (alert && form) {
      form.setFieldsValue({
        name: get(alert, "name"),
        category: get(alert, "category"),
        coin: get(alert, "coin.id"),
        farm: get(alert, "farm.id"),
        type: get(alert, "type"),
        metric: get(alert, "metric"),
        upper: get(alert, "settings.upper"),
        lower: get(alert, "settings.lower"),
      });
    }
  }, [alert, form]);

  const onSubmit = async (data) => {
    const object = {
      id: get(alert, "id"),
      name: get(data, "name"),
      category: get(data, "category"),
      coin: get(data, "coin"),
      farm: get(data, "farm"),
      type: get(data, "type"),
      metric: get(data, "metric"),
      settings: {
        upper: get(data, "upper"),
        lower: get(data, "lower"),
      },
    };
    console.log(object);
    await dispatch(createAlert(object));
    onClose();
  };
  const clickDelete = async () => {
    await dispatch(deleteAlert(get(alert, "id")));
    onClose();
  };
  return (
    <div>
      <PageHeader
        title="Back"
        onBack={() => onClose()}
        extra={
          alert
            ? [
                <Button type="primary" danger onClick={clickDelete}>
                  Delete
                </Button>,
              ]
            : []
        }
      />
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="category" label="Category">
          <Select onChange={setCategory}>
            {Object.values(ALERT_CATEGORY).map((cat) => (
              <Option value={cat} key={cat}>
                {ALERT_CATEGORY_NAME[cat]}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {category === ALERT_CATEGORY.coin && (
          <Form.Item name="coin" label="Coin">
            <Select>
              {coins.map((coin) => (
                <Option value={coin.id} key={coin.id}>
                  {coin.symbol}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {category === ALERT_CATEGORY.farm && (
          <Form.Item name="farm" label="Farm">
            <Select>
              {walletFarms.map((farm) => (
                <Option value={farm.id} key={farm.id}>
                  {farm.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item name="metric" label="Metric">
          <Select>
            {Object.values(ALERT_METRIC[category] || []).map((metric) => (
              <Option value={metric} key={metric}>
                {ALERT_METRIC_NAME[metric]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="type" label="Type">
          <Select onChange={setType}>
            {Object.values(ALERT_TYPE).map((type) => (
              <Option value={type} key={type}>
                {ALERT_TYPE_NAME[type]}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {get(settings, "upper") && (
          <Form.Item name="upper" label="Upper">
            <InputNumber />
          </Form.Item>
        )}

        {get(settings, "lower") && (
          <Form.Item name="lower" label="Lower">
            <InputNumber />
          </Form.Item>
        )}

        <Form.Item {...tailLayout}>
          <Button htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewAlert;
