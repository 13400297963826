import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import network from "../network/network";
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from "./sliceHelper";

const initialState = {
  loading: false,
  hasErrors: false,
};

// Slice
const txsSlice = createSlice({
  name: "txs",
  initialState,
  reducers: {
    loadTxsRequest: (state) => {
      isLoadingRequest(state);
    },
    loadTxsSuccess: (state, { payload }) => {
      finishedLoadingSuccess(state);
      state.txs = payload;
    },
    loadTxsFailed: (state) => {
      finishedLoadingFailure(state);
    },
  },
});

// Selector
export const txsSelector = (state) => get(state, "txs.txs", []);

// Actions
export const { loadTxsRequest, loadTxsSuccess, loadTxsFailed } =
  txsSlice.actions;

export const loadTxs = () => async (dispatch) => {
  try {
    dispatch(loadTxsRequest());
    const result = await network.get({
      url: "/txs/",
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadTxsSuccess(result));
  } catch (err) {
    dispatch(loadTxsFailed());
  }
};

export const deleteTx = (id) => async (dispatch) => {
  try {
    dispatch(loadTxsRequest());
    await network.delete({
      url: `/txs/${id}`,
      data: {},
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadTxs());
  } catch (err) {
    dispatch(loadTxsFailed());
  }
};

export const createTx =
  ({ id, walletFarm, txId, amount, symbol, time, chain }) =>
  async (dispatch) => {
    await network.post({
      url: "/txs/",
      data: {
        id,
        walletFarm,
        txId,
        amount,
        symbol,
        time,
        chain,
      },
      requireAuth: true,
      showMessage: false,
    });
    dispatch(loadTxs());
  };
export default txsSlice.reducer;
