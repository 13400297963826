import React from "react";
import Verify2FA from "../settings/Account/Verify2FA";
import requireAuth from "../hoc/requireAuth";
import { Card, Col, PageHeader, Row, Space } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "lodash";

const Page2FA = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = get(location, "state.from.pathname") || "/farms";
  const onBack = () => {
    localStorage.removeItem("token");
    navigate("/login", { replace: true });
  };

  const onVerified = () => {
    navigate(from, { replace: true });
  };
  return (
    <Row>
      <PageHeader title="Back" onBack={onBack} />
      <Col span={6} offset={8} style={{ marginTop: 40 }}>
        <Card>
          <Verify2FA onVerified={onVerified} />
        </Card>
      </Col>
    </Row>
  );
};

export default requireAuth(Page2FA);
