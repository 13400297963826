import { PageHeader, Form, Input, Button, message, notification } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../slices/auth.slice";

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 13 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 16 },
};
const ChangePass = (props) => {
  const { onBack } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    if (!data.oldPass || !data.newPass || !data.confirmPass)
      return notification.error({ message: "Input password" });
    if (data.newPass !== data.confirmPass)
      return notification.error({ message: "Passwords don't match" });
    await dispatch(changePassword(data));
    onBack();
  };
  return (
    <div>
      <PageHeader title="Back" onBack={() => onBack()} />
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item name="oldPass" label="Current Password">
          <Input.Password />
        </Form.Item>
        <Form.Item name="newPass" label="New Password">
          <Input.Password />
        </Form.Item>
        <Form.Item name="confirmPass" label="Confirm Password">
          <Input.Password />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePass;
