export const STATEMENT_TYPE = {
  subscription: "subscription",
  redemption: "redemption",
  accountStatement: "accountStatement",
};

export const STATEMENT_TYPE_NAME = {
  subscription: "Subscription",
  redemption: "Redemption",
  accountStatement: "Account Statement",
};
