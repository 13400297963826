import { EditOutlined } from "@ant-design/icons";
import { Button, PageHeader, Table } from "antd";
import { get } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { STATEMENT_TYPE_NAME } from "../../../constants/statements";
import { statementsSelector } from "../../../slices/statement.slice";
import { includeInsensitiveStr } from "../../../utils/general";
import SearchBox from "../../commons/SearchBox";
import NewStatement from "./NewStatement";

const StatementList = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [statement, setStatement] = useState();
  const statements = useSelector(statementsSelector);
  const [filterData, setFilterData] = useState([]);

  const filterFn = (row, searchTxt) => {
    if (!row.user) return false;
    return includeInsensitiveStr(get(row, "title"), searchTxt);
  };

  if (isEditing) {
    return (
      <NewStatement
        onClose={() => {
          setIsEditing(false);
          setStatement(null);
        }}
        statement={statement}
      />
    );
  }

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (user) =>
        `${get(user, "firstName", "")} ${get(user, "lastName", "")}`,
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
      render: (type) => STATEMENT_TYPE_NAME[type],
    },
    {
      title: "Created",
      key: "updated",
      dataIndex: "createdAt",
      render: (time) => moment(time).format("DD MMM YY HH:mm"),
    },
    {
      title: "Action",
      key: "action",
      render: (statement) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            setIsEditing(true);
            setStatement(statement);
          }}
        />
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        extra={[
          <Button key={"New Statement"} onClick={() => setIsEditing(true)}>
            New Statement
          </Button>,
        ]}
      />
      <SearchBox
        source={statements}
        onFiltered={setFilterData}
        filterFn={filterFn}
      />
      <Table dataSource={filterData} columns={columns} />
    </div>
  );
};

export default StatementList;
