import {
  Button,
  Form,
  Input,
  InputNumber,
  Space,
  PageHeader,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import Avatar from "antd/lib/avatar/avatar";
import { createChain, deleteChain } from "../../../slices/chain.slice";

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 16 },
};

const NewChain = (props) => {
  const { chain, onClose } = props;
  const [form] = Form.useForm();
  const [files, setFiles] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (chain && form) {
      form.setFieldsValue({
        name: get(chain, "name"),
        chainId: get(chain, "chainId"),
        explorer: get(chain, "explorer"),
      });
    }
  }, [chain, form]);

  const onSubmit = async (data) => {
    await dispatch(
      createChain({
        id: get(chain, "id"),
        file: files.length && files[0],
        name: get(data, "name"),
        chainId: get(data, "chainId"),
        explorer: get(data, "explorer"),
      })
    );
    onClose();
  };

  const clickDelete = async () => {
    await dispatch(deleteChain(get(chain, "id")));
    onClose();
  };
  return (
    <div>
      <PageHeader
        title="Back"
        onBack={() => onClose()}
        extra={
          chain
            ? [
                <Button type="primary" danger onClick={clickDelete}>
                  Delete
                </Button>,
              ]
            : []
        }
      />
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="explorer" label="Explorer">
          <Input />
        </Form.Item>
        <Form.Item name="chainId" label="Chain ID">
          <InputNumber />
        </Form.Item>
        <Form.Item name="image" label="Image">
          <Space>
            <Avatar src={get(chain, "image")} />
            <Upload
              fileList={files}
              beforeUpload={() => false}
              onChange={({ fileList }) => setFiles(fileList)}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Space>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewChain;
