import { useEffect, useState } from "react";
import { Input } from "antd";

const SearchBox = (props) => {
  const { source, onFiltered, filterFn } = props;
  const [searchTxt, setSearchTxt] = useState("");
  useEffect(() => {
    let filterData;
    if (!searchTxt || searchTxt === "") {
      filterData = source;
    } else {
      filterData = source.filter((tx) => filterFn(tx, searchTxt));
    }
    onFiltered(filterData);
  }, [searchTxt, source.length]);
  return (
    <div>
      <Input.Search
        placeholder="input text to search"
        onSearch={setSearchTxt}
        defaultValue={searchTxt}
        allowClear
      />
    </div>
  );
};

export default SearchBox;
