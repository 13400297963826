import { EditOutlined } from "@ant-design/icons";
import { Button, PageHeader, Table } from "antd";
import { get } from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { farmsSelector } from "../../../slices/farms.slice";
import { includeInsensitiveStr } from "../../../utils/general";
import SearchBox from "../../commons/SearchBox";
import NewFarm from "./NewFarm";

const FarmList = () => {
  const farms = useSelector(farmsSelector) || [];
  const [farm, setFarm] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const filterFn = (row, searchTxt) => {
    return includeInsensitiveStr(get(row, "name"), searchTxt);
  };
  if (isEditing)
    return (
      <NewFarm
        farm={farm}
        onClose={() => {
          setIsEditing(false);
          setFarm(null);
        }}
      />
    );
  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Chain",
      dataIndex: "chain",
      key: "chain",
      render: (chain) => get(chain, "name"),
    },
    {
      title: "Address",
      key: "address",
      render: (farm) => (
        <a
          href={`${get(farm, "chain.explorer")}address/${farm.address}`}
          target="_blank"
          rel="noreferrer"
        >
          {farm.address}
        </a>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (farm) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            setFarm(farm);
            setIsEditing(true);
          }}
        />
      ),
    },
  ];
  return (
    <div>
      <PageHeader
        extra={[
          <Button onClick={() => setIsEditing(true)}>New Farm Type</Button>,
        ]}
      ></PageHeader>
      <SearchBox
        source={farms}
        onFiltered={setFilterData}
        filterFn={filterFn}
      />
      <Table dataSource={filterData} columns={columns} />
    </div>
  );
};

export default FarmList;
