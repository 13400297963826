import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { createTx, deleteTx } from "../../../slices/tx.slice";
import { walletFarmsSelector } from "../../../slices/farms.slice";
import moment from "moment";
const { Option } = Select;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 16 },
};

const NewTx = (props) => {
  const { tx, onClose } = props;
  const [form] = Form.useForm();

  const walletFarms = useSelector(walletFarmsSelector) || [];
  const [walletFarm, setWalletFarm] = useState();

  const onFarmChanged = (farmId) => {
    const walletFarm = walletFarms.find((wf) => wf.id === farmId);
    setWalletFarm(walletFarm);
  };

  const coins = useMemo(() => {
    return get(walletFarm, "rewardTokens", []);
  }, [walletFarm]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (tx && form) {
      const time = moment.unix(get(tx, "time"));
      onFarmChanged(get(tx, "walletFarm.id"));
      form.setFieldsValue({
        walletFarm: get(tx, "walletFarm.id"),
        txId: get(tx, "txId"),
        symbol: get(tx, "symbol"),
        amount: get(tx, "amount"),
        time,
      });
    }
  }, [tx, form]);

  const onSubmit = async (data) => {
    await dispatch(
      createTx({
        id: get(tx, "id"),
        walletFarm: get(data, "walletFarm"),
        txId: get(data, "txId"),
        symbol: get(data, "symbol"),
        amount: get(data, "amount"),
        time: get(data, "time", moment()).unix(),
        chain: get(walletFarm, "farm.chain"),
      })
    );
    onClose();
  };

  const clickDelete = async () => {
    await dispatch(deleteTx(get(tx, "id")));
    onClose();
  };

  return (
    <div>
      <PageHeader
        title="Back"
        onBack={() => onClose()}
        extra={
          tx
            ? [
                <Button type="primary" danger onClick={clickDelete}>
                  Delete
                </Button>,
              ]
            : []
        }
      />
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item name="walletFarm" label="Farm">
          <Select onChange={onFarmChanged}>
            {walletFarms.map((wf) => (
              <Option value={wf.id}>{get(wf, "name")}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="symbol" label="Coin">
          <Select>
            {coins.map((coin) => (
              <Option value={coin.symbol}>{get(coin, "symbol")}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="amount" label="Amount">
          <InputNumber />
        </Form.Item>
        <Form.Item name="txId" label="Tx ID">
          <Input />
        </Form.Item>
        <Form.Item name="time" label="Time Withdrawed">
          <DatePicker />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewTx;
