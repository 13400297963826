import { Button, Form, Input, PageHeader, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, get, keyBy, map } from "lodash";
import { createFarm, deleteFarm } from "../../../slices/farms.slice";
import { chainsSelector } from "../../../slices/chain.slice";
import { COIN_SETTINGS, FARMS } from "../../../constants/farms";
import { PlusOutlined } from "@ant-design/icons";
import NormalCoin from "./NormalCoin";
import CustomCoin from "./CustomCoin";
import { coinsSelector } from "../../../slices/coins.slice";

const { Option } = Select;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 3, span: 16 },
};

const NewFarm = (props) => {
  const { farm, onClose } = props;
  const [form] = Form.useForm();
  const chains = useSelector(chainsSelector) || [];
  const [farmType, setFarmType] = useState(get(farm, "farmType"));
  const [chain, setChain] = useState(get(farm, "chain.id"));
  const settings = COIN_SETTINGS[farmType];
  const coins = useSelector(coinsSelector);

  const filterCoins = coins.filter((coin) => {
    const chains = map(coin.chainAddrs, "chain");
    return chains.includes(chain);
  });
  const mapCoins = keyBy(filterCoins, "symbol");

  const dispatch = useDispatch();

  useEffect(() => {
    if (farm && form) {
      form.setFieldsValue({
        name: get(farm, "name"),
        chain: get(farm, "chain.id"),
        address: get(farm, "address"),
        farmType: get(farm, "farmType"),
        abi: get(farm, "abi"),
        coins: get(farm, "coins"),
        lpTokens: get(farm, "lpTokens"),
        rewardTokens: get(farm, "rewardTokens"),
        solanaQuarry: get(farm, "solanaQuarry"),
      });
    }
  }, [farm, form]);

  const getCoinInfo = (type, data) => {
    return get(data, type, []).map((coin) => {
      const selectCoin = mapCoins[coin.symbol];
      const chainAddr = selectCoin.chainAddrs.find((ca) => ca.chain === chain);
      return {
        ...coin,
        name: get(selectCoin, "name"),
        address: get(chainAddr, "address"),
        decimals: get(chainAddr, "decimals"),
      };
    });
  };
  const onSubmit = async (data) => {
    const coins = getCoinInfo("coins", data);
    const lpTokens = getCoinInfo("lpTokens", data);
    const rewardTokens = getCoinInfo("rewardTokens", data);
    await dispatch(
      createFarm({
        ...data,
        coins,
        lpTokens,
        rewardTokens,
        id: get(farm, "id"),
      })
    );
    onClose();
  };

  const clickDelete = async () => {
    await dispatch(deleteFarm(get(farm, "id")));
    onClose();
  };
  return (
    <div>
      <PageHeader
        title="Back"
        onBack={() => onClose()}
        extra={
          farm
            ? [
                <Button type="primary" danger onClick={clickDelete}>
                  Delete
                </Button>,
              ]
            : []
        }
      />
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="address" label="Address">
          <Input />
        </Form.Item>
        <Form.Item
          name="chain"
          label="Chain"
          initialValue={get(farm, "chain.id")}
        >
          <Select onChange={setChain}>
            {chains.map((chain) => (
              <Option value={chain.id} key={chain.id}>
                {chain.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="farmType" label="Farm Type">
          <Select onChange={setFarmType}>
            {Object.values(FARMS).map((type) => (
              <Option value={type} key={type}>
                {capitalize(type)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {get(settings, "solanaQuarry") && (
          <Form.Item name="solanaQuarry" label="Solana Quarry">
            <Input />
          </Form.Item>
        )}
        {get(settings, "abi") && (
          <Form.Item name="abi" label="Abi">
            <Input />
          </Form.Item>
        )}
        {get(settings, "coins") && (
          <Form.Item label="Coins">
            <Form.List name="coins">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <NormalCoin
                      field={field}
                      remove={remove}
                      coins={filterCoins}
                    />
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Coin
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        )}

        {get(settings, "lpTokens") && (
          <Form.Item label="Lp Tokens">
            <Form.List name="lpTokens">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <CustomCoin
                      field={field}
                      remove={remove}
                      settings={get(settings, "lpTokens")}
                      coins={filterCoins}
                    />
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Token
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        )}
        {get(settings, "rewardTokens") && (
          <Form.Item label="Reward Tokens">
            <Form.List name="rewardTokens">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <CustomCoin
                      field={field}
                      remove={remove}
                      settings={get(settings, "rewardTokens")}
                      coins={filterCoins}
                    />
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Token
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        )}
        <Form.Item {...tailLayout}>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewFarm;
