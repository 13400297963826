import React from "react";
import { Card, Table, Typography } from "antd";
import { round } from "lodash";
import { numberWithCommas } from "../../../utils/general";
const { Title } = Typography;

const WalletPanel = (props) => {
  const { wallet } = props;
  const columns = [
    {
      title: "Coin",
      key: "coin",
      dataIndex: "name",
    },
    {
      title: "Symbol",
      key: "symbol",
      dataIndex: "symbol",
    },
    {
      title: "Balance",
      key: "balance",
      dataIndex: "bal",
      render: (bal) => numberWithCommas(round(bal, 5)),
    },
    {
      title: "Balance USD",
      key: "balanceUsd",
      dataIndex: "balUsd",
      render: (val) => numberWithCommas(val),
    },
  ];
  return (
    <Card>
      <Title level={5}>{wallet.name}</Title>
      <Table columns={columns} dataSource={wallet.coins} />
    </Card>
  );
};

export default WalletPanel;
